import { useCallback, useState } from 'react';
import { Alert } from '../../components';
import ToastContext, { ShowToastProps, ToastProps } from './ToastContext';

const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  // State to hold the list of toasts.
  const [toasts, setToasts] = useState<ToastProps[]>([]);

  // Function to remove a toast by its id.
  const hideToast = useCallback(
    (id: number) => setToasts((toasts) => toasts.filter((toast) => toast.id !== id)),
    [setToasts]
  );

  // Function to add a new toast with a message.
  const showToast = useCallback(
    ({ delay = 3000, ...props }: ShowToastProps) => {
      const id = Date.now();
      // Set a timeout to remove the toast after 3 seconds.
      setTimeout(() => hideToast(id), delay);
      // Add the new toast to the beginning of the list.
      setToasts([{ id, ...props }, ...toasts]);
    },
    [hideToast]
  );

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {/* Z-index 1000 is used to ensure that the toast is displayed above other elements */}
      <div className="toast z-[1000]">
        {toasts.map(({ id, ...rest }) => (
          <Alert key={id} {...rest} />
        ))}
      </div>
    </ToastContext.Provider>
  );
};

export default ToastProvider;
