import { ButtonColor, Icon, TextButton } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { randomizeObjections, setObjections } from '../../../../redux/reducers';
import TabSection from '../TabSection';
import ScenarioListItem from './ScenarioListItem';

const Objections = () => {
  const dispatch = useAppDispatch();
  const objections = useAppSelector((state) => state.prospect.scenario.objections) || [];

  // Adds an empty objection field.
  const onAddObjection = () => {
    dispatch(setObjections([...objections, { value: '' }]));
  };

  return (
    <TabSection title="Objections" onRandomize={() => dispatch(randomizeObjections())}>
      {objections.map((objection, index) => (
        <ScenarioListItem
          key={index}
          index={index}
          items={objections}
          value={objection.value}
          setItems={(newItems) => dispatch(setObjections(newItems))}
        />
      ))}
      <TextButton text="Add objection" startIcon={Icon.PLUS} color={ButtonColor.SECONDARY} onClick={onAddObjection} />
    </TabSection>
  );
};

export default Objections;
