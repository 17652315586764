import { setOrgId, setProspectType } from '../../../redux/reducers';
import { ComponentSize, ProspectType } from '../../../types';
import { ButtonColor, TextButton } from '../../shared';

import { useAppDispatch } from '../../../hooks';
import { ButtonGroup } from '../../shared';

interface ProspectDesignerModalActionsProps {
  isLoading: boolean;
  selectedProspectType: ProspectType;
  onCancel: () => void;
  selectedOrgId?: string;
}

const ProspectDesignerModalActions = ({
  isLoading,
  selectedProspectType,
  onCancel,
  selectedOrgId,
}: ProspectDesignerModalActionsProps) => {
  const dispatch = useAppDispatch();

  // Sets the org id and prospect type in the redux store.
  // This automatically closes the designer modal since it only opens if orgId or prospectType are not set.
  const handleCreateProspect = () => {
    if (selectedOrgId) {
      dispatch(setOrgId(selectedOrgId));
      dispatch(setProspectType(selectedProspectType));
    }
  };

  return (
    <ButtonGroup size={ComponentSize.MEDIUM}>
      <TextButton
        onClick={handleCreateProspect}
        text="Create prospect"
        color={ButtonColor.PRIMARY}
        disabled={isLoading}
      />
      <TextButton onClick={onCancel} text="Cancel" />
    </ButtonGroup>
  );
};

export default ProspectDesignerModalActions;
