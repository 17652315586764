import { Dispatch, SetStateAction } from 'react';
import { Tab } from '../../../components';
import { ProspectPageMode, ProspectPageTabs } from '../../../types';
import CreateFooter from './CreateFooter';
import EditFooter from './EditFooter';

interface ProspectPageFooterProps {
  activeTabIndex: number;
  mode: ProspectPageMode;
  tabs: Tab[];
  setActiveTab: Dispatch<SetStateAction<ProspectPageTabs>>;
  setMode: Dispatch<SetStateAction<ProspectPageMode>>;
  setVisitedTabs: Dispatch<SetStateAction<Set<ProspectPageTabs>>>;
}

const ProspectPageFooter = ({
  activeTabIndex,
  mode,
  tabs,
  setActiveTab,
  setMode,
  setVisitedTabs,
}: ProspectPageFooterProps) => {
  // TODO: Show view mode footer.
  if (mode === ProspectPageMode.VIEW) {
    return null;
  }

  return (
    <div className="flex w-full justify-end border-t border-base-100 p-8">
      {mode === ProspectPageMode.CREATE && (
        <CreateFooter
          activeTabIndex={activeTabIndex}
          tabs={tabs}
          setActiveTab={setActiveTab}
          setMode={setMode}
          setVisitedTabs={setVisitedTabs}
        />
      )}
      {mode === ProspectPageMode.EDIT && <EditFooter setActiveTab={setActiveTab} setMode={setMode} />}
    </div>
  );
};

export default ProspectPageFooter;
