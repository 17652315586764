import { ComponentSize } from '../../../types';

export const SLIDER_DEFAULT_MIN = 1;
export const SLIDER_DEFAULT_MAX = 5;
export const SLIDER_DEFAULT_STEP = 1;

export const THUMB_BORDER_WIDTH = 4;

/** Maps the size of the slider to the height in pixels. */
export const SLIDER_SIZE_TO_PX: Record<ComponentSize, number> = {
  [ComponentSize.X_SMALL]: 16,
  [ComponentSize.SMALL]: 20,
  [ComponentSize.MEDIUM]: 24,
  [ComponentSize.LARGE]: 32,
};

/** Maps the size of the slider to the size class of the track. */
export const SLIDER_SIZE_TO_TRACK_CLASSNAME: Record<ComponentSize, string> = {
  [ComponentSize.X_SMALL]: 'h-1',
  [ComponentSize.SMALL]: 'h-1',
  [ComponentSize.MEDIUM]: 'h-2',
  [ComponentSize.LARGE]: 'h-4',
};
