import { Icon } from '../../../components';
import { Icons, Typography } from '../../../components';
import { ComponentSize } from '../../../types';

interface TabEmptyStateProps {
  text: string;
}

const TabEmptyState = ({ text }: TabEmptyStateProps) => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-4">
      <Icons icon={Icon.TRIANGLE_ALERT} size={ComponentSize.LARGE} />
      <Typography>{text}</Typography>
    </div>
  );
};

export default TabEmptyState;
