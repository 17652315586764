import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Call } from '../../types';

export enum BannerTypes {
  ZEUS = 'zeus',
}

export enum SettingsModalTabs {
  PROFILE = 'profile',
  ORGANIZATION = 'organization',
  SCORECARDS = 'scorecards',
  HIRING = 'hiring',
  INTEGRATIONS = 'integrations',
}

/**
 * The call can either be passed in as a prop, or fetched from the API.
 * If the call is passed in as a prop, we skip the call query.
 */
export type FlagCallModalState = { call: Call; callSid?: undefined } | { callSid: string; call?: undefined };

export interface UnflagConfirmModalState {
  callSid: string;
}

interface SettingsModalState {
  isOpen: boolean;
  activeTab: SettingsModalTabs;
  scorecardForm: {
    isOpen: boolean;
  };
}

interface ModalState {
  /** Array of banners to be displayed at the top of the page. */
  banners: Array<BannerTypes>;
  isCreateProspectModalOpen: boolean;
  isUploadModalOpen: boolean;
  isZeusModalOpen: boolean;
  isGongCallsModalOpen: boolean;
  settings: SettingsModalState;
  requestReviewModal?: FlagCallModalState;
  unflagConfirmModal?: UnflagConfirmModalState;
}

const initialModalState: ModalState = {
  banners: [],
  isCreateProspectModalOpen: false,
  isUploadModalOpen: false,
  isZeusModalOpen: false,
  isGongCallsModalOpen: false,
  settings: {
    isOpen: false,
    activeTab: SettingsModalTabs.PROFILE,
    scorecardForm: {
      isOpen: false,
    },
  },
};

const modalSlice = createSlice({
  name: 'modal',
  initialState: initialModalState,
  reducers: {
    openBanner: (state, action: PayloadAction<BannerTypes>) => {
      state.banners = [...state.banners.filter((banner) => banner !== action.payload), action.payload];
    },
    closeBanner: (state, action: PayloadAction<BannerTypes>) => {
      state.banners = state.banners.filter((banner) => banner !== action.payload);
    },
    openCreateProspectModal: (state) => {
      state.isCreateProspectModalOpen = true;
    },
    closeCreateProspectModal: (state) => {
      state.isCreateProspectModalOpen = false;
    },
    openRequestReviewModal: (state, action: PayloadAction<FlagCallModalState>) => {
      state.requestReviewModal = action.payload;
    },
    closeRequestReviewModal: (state) => {
      state.requestReviewModal = undefined;
    },
    openSettingsModal: (state, action: PayloadAction<SettingsModalTabs | undefined>) => {
      state.settings.isOpen = true;
      if (action.payload) {
        state.settings.activeTab = action.payload;
      } else {
        state.settings.activeTab = SettingsModalTabs.PROFILE;
      }
    },
    closeSettingsModal: (state) => {
      state.settings.isOpen = false;
      state.settings.scorecardForm.isOpen = false;
    },
    setSettingsActiveTab: (state, action: PayloadAction<SettingsModalTabs>) => {
      state.settings.activeTab = action.payload;
    },
    openUnflagConfirmModal: (state, action: PayloadAction<string>) => {
      state.unflagConfirmModal = { callSid: action.payload };
    },
    closeUnflagConfirmModal: (state) => {
      state.unflagConfirmModal = undefined;
    },
    openUploadModal: (state) => {
      state.isUploadModalOpen = true;
    },
    closeUploadModal: (state) => {
      state.isUploadModalOpen = false;
    },
    openScorecardForm: (state) => {
      // Opens settings modal on scorecards tab, with scorecard form open
      state.settings.isOpen = true;
      state.settings.activeTab = SettingsModalTabs.SCORECARDS;
      state.settings.scorecardForm.isOpen = true;
    },
    closeScorecardForm: (state) => {
      state.settings.scorecardForm.isOpen = false;
    },
    openZeusModal: (state) => {
      state.isZeusModalOpen = true;
    },
    closeZeusModal: (state) => {
      state.isZeusModalOpen = false;
    },
    openGongCallsModal: (state) => {
      state.isGongCallsModalOpen = true;
    },
    closeGongCallsModal: (state) => {
      state.isGongCallsModalOpen = false;
    },
  },
});

export const {
  openBanner,
  closeBanner,
  openCreateProspectModal,
  closeCreateProspectModal,
  openRequestReviewModal,
  closeRequestReviewModal,
  openSettingsModal,
  closeSettingsModal,
  setSettingsActiveTab,
  openUploadModal,
  closeUploadModal,
  openUnflagConfirmModal,
  closeUnflagConfirmModal,
  openScorecardForm,
  closeScorecardForm,
  openZeusModal,
  closeZeusModal,
  openGongCallsModal,
  closeGongCallsModal,
} = modalSlice.actions;
export default modalSlice.reducer;
