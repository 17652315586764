import { Slider, Typography } from '../../../../components';
import { PROSPECT_SLIDER_LIMITS } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  randomizePersonality,
  setEmpathyLevel,
  setImprovisationPreference,
  setTalkativenessLevel,
  setThinkingStyle,
} from '../../../../redux/reducers';
import { Alignment } from '../../../../types';
import TabSection from '../TabSection';

const Personality = () => {
  const dispatch = useAppDispatch();
  const personal = useAppSelector((state) => state.prospect.personal);

  const fields = [
    {
      leftLabel: 'Talkative and outgoing',
      rightLabel: 'Reserved and private',
      value: personal.talkativenessLevel.value,
      onChange: (value: number) => {
        dispatch(setTalkativenessLevel({ value, isUserModified: true }));
      },
    },
    {
      leftLabel: 'Literal thinker',
      rightLabel: 'Figurative thinker',
      value: personal.thinkingStyle.value,
      onChange: (value: number) => {
        dispatch(setThinkingStyle({ value, isUserModified: true }));
      },
    },
    {
      leftLabel: 'Reasonable and level-headed',
      rightLabel: 'Warm and empathetic',
      value: personal.empathyLevel.value,
      onChange: (value: number) => {
        dispatch(setEmpathyLevel({ value, isUserModified: true }));
      },
    },
    {
      leftLabel: 'Prefers detailed step-by-step instructions',
      rightLabel: 'Likes to improvise and make things up as you go',
      value: personal.improvisationPreference.value,
      onChange: (value: number) => {
        dispatch(setImprovisationPreference({ value, isUserModified: true }));
      },
    },
  ];

  const renderLabel = (label: string, alignment: Alignment) => (
    <Typography className="w-56 select-none" alignment={alignment}>
      {label}
    </Typography>
  );

  return (
    <TabSection title="Personality" description="Key traits" onRandomize={() => dispatch(randomizePersonality())}>
      <div className="flex flex-col items-center gap-4">
        {fields.map((field, index) => (
          <div className="flex w-full items-start gap-2 self-stretch" key={index}>
            {renderLabel(field.leftLabel, Alignment.RIGHT)}
            <Slider
              className="flex-1"
              hideProgress
              hideStep
              max={PROSPECT_SLIDER_LIMITS.max}
              min={PROSPECT_SLIDER_LIMITS.min}
              step={PROSPECT_SLIDER_LIMITS.step}
              value={field.value}
              onChange={(value) => field.onChange(value)}
            />
            {renderLabel(field.rightLabel, Alignment.LEFT)}
          </div>
        ))}
      </div>
    </TabSection>
  );
};

export default Personality;
