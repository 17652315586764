import { Select, Slider, Typography } from '../../../../components';
import { PROSPECT_SLIDER_LIMITS } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { randomizeOutcome, setSuccessDifficultyLevel, setSuccessResult } from '../../../../redux/reducers';
import { ProspectSuccessResult } from '../../../../types';
import ProspectPageField from '../ProspectPageField';
import TabSection from '../TabSection';

const SUCCESSFUL_RESULT_SELECT_WIDTH = 200;

const Outcome = () => {
  const dispatch = useAppDispatch();
  const { successResult, successDifficultyLevel } = useAppSelector((state) => state.prospect.scenario);

  const successResultOptions = Object.values(ProspectSuccessResult).map((value) => ({ label: value, value }));
  const selectedSuccessResultOption = successResultOptions.find((option) => option.value === successResult.value);

  const handleSuccessResultChange = (value?: string) => {
    const newSuccessResult = value as ProspectSuccessResult;
    dispatch(setSuccessResult({ value: newSuccessResult, isUserModified: !!newSuccessResult }));
  };

  const handleDifficultyChange = (value: number) => {
    dispatch(setSuccessDifficultyLevel({ value, isUserModified: true }));
  };

  return (
    <TabSection title="Outcome" onRandomize={() => dispatch(randomizeOutcome())}>
      <div className="flex items-center gap-4">
        <ProspectPageField label="Successful result*">
          <Select
            clearable
            selected={selectedSuccessResultOption}
            options={successResultOptions}
            width={SUCCESSFUL_RESULT_SELECT_WIDTH}
            onChange={handleSuccessResultChange}
          />
        </ProspectPageField>
        <ProspectPageField fullWidth label="Difficulty">
          <div className="flex items-center gap-4">
            <Typography className="select-none">Easy</Typography>
            <Slider
              hideProgress
              hideStep
              max={PROSPECT_SLIDER_LIMITS.max}
              min={PROSPECT_SLIDER_LIMITS.min}
              step={PROSPECT_SLIDER_LIMITS.step}
              value={successDifficultyLevel.value}
              onChange={handleDifficultyChange}
            />
            <Typography className="select-none">Difficult</Typography>
          </div>
        </ProspectPageField>
      </div>
    </TabSection>
  );
};

export default Outcome;
