import { useCallback, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { useHandleApiResponse, useToast } from '../../../hooks';
import { useAddCommentMutation } from '../../../services';
import { ComponentSize, DropdownPlacement } from '../../../types';
import { formatSecondsToDuration } from '../../../utils';
import {
  AlertType,
  ButtonColor,
  Dropdown,
  DropdownContent,
  DropdownTrigger,
  Icon,
  IconButton,
  Icons,
  Spinner,
  TextButton,
  TextInput,
} from '../../shared';

const ERROR_MSG = 'Failed to add comment';

interface CommentButtonProps {
  callSid: string;
  currentTime: number;
  isCompact: boolean;
  waveSurfer: WaveSurfer;
}

const CommentButton = ({ callSid, currentTime, isCompact, waveSurfer }: CommentButtonProps) => {
  // State to keep track of the comment text input.
  const [commentInput, setCommentInput] = useState('');
  // State to control the comment dropdown open/close state.
  const [isCommentDropdownOpen, setIsCommentDropdownOpen] = useState(false);

  const { showToast } = useToast();
  const handleApiResponse = useHandleApiResponse();

  const [addComment, { isLoading }] = useAddCommentMutation();

  // Handles toggling the comment dropdown open/close state.
  const toggleCommentDropdown = useCallback(() => {
    if (isCommentDropdownOpen) {
      // Close the dropdown and reset the input state.
      setIsCommentDropdownOpen(false);
      setCommentInput('');
    } else {
      if (waveSurfer.isPlaying()) {
        waveSurfer.pause();
      }
      setIsCommentDropdownOpen(true);
    }
  }, [isCommentDropdownOpen, waveSurfer, setIsCommentDropdownOpen, setCommentInput]);

  const sendComment = useCallback(async () => {
    const trimmedComment = commentInput.trim();
    if (!trimmedComment.length) return;

    try {
      const response = await addComment({ callSid, text: trimmedComment, timestamp: currentTime });
      handleApiResponse({ response, errorMsg: ERROR_MSG, onSuccess: toggleCommentDropdown });
    } catch (error) {
      console.error(`${ERROR_MSG}: `, error);
      showToast({ message: ERROR_MSG, type: AlertType.ERROR });
    }
  }, [callSid, commentInput, currentTime, handleApiResponse, toggleCommentDropdown]);

  const action = {
    icon: Icon.COMMENT,
    text: 'Comment',
    onClick: toggleCommentDropdown,
    active: isCommentDropdownOpen,
    color: ButtonColor.SECONDARY,
  };

  return (
    <Dropdown open={isCommentDropdownOpen} onOpenChange={toggleCommentDropdown} placement={DropdownPlacement.TOP_START}>
      <DropdownTrigger>
        {isCompact && (
          <IconButton
            icon={action.icon}
            onClick={action.onClick}
            color={action.color}
            active={action.active}
            tooltip={action.text}
          />
        )}
        {!isCompact && (
          <TextButton
            text={action.text}
            startIcon={action.icon}
            onClick={action.onClick}
            active={action.active}
            color={action.color}
          />
        )}
      </DropdownTrigger>
      <DropdownContent>
        <TextInput
          placeholder={`Leave a comment at ${formatSecondsToDuration(currentTime)}`}
          endElement={
            isLoading ? (
              <Spinner size={ComponentSize.X_SMALL} />
            ) : (
              <Icons icon={Icon.SEND} size={ComponentSize.X_SMALL} onClick={sendComment} />
            )
          }
          width={300}
          value={commentInput}
          size={ComponentSize.X_SMALL}
          onChange={(e) => setCommentInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              sendComment();
            }
          }}
        />
      </DropdownContent>
    </Dropdown>
  );
};

export default CommentButton;
