import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface GongState {
  isOAuthConnectionSuccessful?: boolean;
}

const INITIAL_GONG_STATE: GongState = {
  isOAuthConnectionSuccessful: undefined,
};

const gongReducer = createSlice({
  name: 'gong',
  initialState: INITIAL_GONG_STATE,
  reducers: {
    setOAuthConnectionAttempt: (state, action: PayloadAction<boolean>) => {
      state.isOAuthConnectionSuccessful = action.payload;
    },
  },
});

export const { setOAuthConnectionAttempt } = gongReducer.actions;
export default gongReducer.reducer;
