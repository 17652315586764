import { useCallback } from 'react';
import { useGenerateGongOauthStateMutation } from '../../../../../services';
import ConnectButton from './ConnectButton';
import IntegrationSectionHeader from './IntegrationSectionHeader';
import { useAppSelector, useToast } from '../../../../../hooks';
import { ApiPostError } from '../../../../../types';
import { AlertType } from '../../../../shared';

const BACKEND_URL = process.env.REACT_APP_API_BASE_URL as string;
const GONG_CLIENT_ID = process.env.REACT_APP_GONG_CLIENT_ID;

const ConnectGongOAuth = () => {
  const [generateGongOauthState, { isLoading: isGenerating }] = useGenerateGongOauthStateMutation();
  const { showToast } = useToast();

  const { isOAuthConnectionSuccessful } = useAppSelector((state) => state.gong) || {};

  // Handle the success response
  const onSuccess = useCallback((state: string) => {
    // Define the redirect URI
    const redirectUri = encodeURIComponent(`${BACKEND_URL}/gong/oauth-callback`);

    // Define the scopes
    const scopes = encodeURIComponent(
      'api:calls:read:transcript api:workspaces:read api:calls:read:extensive api:settings:scorecards:read api:stats:scorecards api:calls:read:basic api:calls:read:media-url api:library:read api:crm-calls:manual-association:read'
    );

    // Generate the authorization URL
    const authorizationUrl = `https://app.gong.io/oauth2/authorize?response_type=code&client_id=${GONG_CLIENT_ID}&redirect_uri=${redirectUri}&scope=${scopes}&state=${state}`;

    // Redirect to the authorization URL
    window.location.href = authorizationUrl;

    // We don't show success toast here because it's handled after the redirect
  }, []);

  // Handle the error response
  const onError = useCallback(
    (error: ApiPostError) => {
      showToast({
        message: 'OAuth process failed.',
        type: AlertType.ERROR,
      });
      console.error('OAuth process failed.', error);
    },
    [showToast]
  );

  // Handle the OAuth connection
  const handleOauthConnect = useCallback(async () => {
    try {
      // Generate the OAuth state
      const { data: state, error } = await generateGongOauthState();

      // Handle the response
      if (error) return onError(error);
      if (state) return onSuccess(state);
    } catch (error) {
      onError(error as ApiPostError);
    }
  }, [generateGongOauthState, onError, onSuccess]);

  return (
    <div className="flex flex-col gap-4">
      <IntegrationSectionHeader
        title="Gong OAuth (Preferred)"
        description="FullyRamped will gain read permissions to read data from your Gong instance. User must have Tech Admin role in Gong."
      />
      <ConnectButton
        onConnect={handleOauthConnect}
        isLoading={isGenerating}
        isFailedConnection={isOAuthConnectionSuccessful == false}
      />
    </div>
  );
};

export default ConnectGongOAuth;
