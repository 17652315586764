import { HOTLINE_DEFAULT_PHONE_NUMBER, HOTLINE_DEFAULT_PROSPECT_NAME } from '../../../../../constants';
import { useAppDispatch, useToast } from '../../../../../hooks';
import { closeCreateProspectModal, startWebCall } from '../../../../../redux/reducers';
import { useGetHotlinePracticeProspectQuery } from '../../../../../services';
import { TextColor } from '../../../../../types';
import {
  AlertType,
  ButtonColor,
  ButtonVariant,
  Icon,
  IconButton,
  TextButton,
  Typography,
  TypographySize,
} from '../../../../shared';

const ProspectHotlineItem = () => {
  // Redux
  const dispatch = useAppDispatch();

  // Custom hooks
  const { showToast } = useToast();

  // Queries
  const { data: hotlineProspect } = useGetHotlinePracticeProspectQuery();

  // Copies the hotline phone number to the clipboard.
  // If the hotline prospect is not found, it copies the default hotline phone number.
  const handleCopyPhoneNumber = () => {
    if (hotlineProspect) {
      window.navigator.clipboard.writeText(hotlineProspect.associatedPhoneNumber);
    } else {
      window.navigator.clipboard.writeText(HOTLINE_DEFAULT_PHONE_NUMBER);
    }
    showToast({
      message: 'Phone number copied to clipboard',
      type: AlertType.SUCCESS,
    });
  };

  // Web calls the hotline prospect.
  // If the hotline prospect is not found, it directly dials the default hotline phone number.
  const handleCallNow = () => {
    if (hotlineProspect) {
      dispatch(closeCreateProspectModal());
      dispatch(startWebCall({ prospect: hotlineProspect, hideUpNext: true }));
    } else {
      window.location.href = `tel:${HOTLINE_DEFAULT_PHONE_NUMBER}`;
    }
  };

  return (
    <div className="flex items-center justify-between gap-2">
      <Typography size={TypographySize.H5} color={TextColor.SECONDARY}>
        Call {hotlineProspect?.firstName ?? HOTLINE_DEFAULT_PROSPECT_NAME} 24/7 to create a new prospect. She&apos;ll
        ask you questions about the job title and scenario you want to practice!
      </Typography>
      <div className="flex gap-2">
        <IconButton
          icon={Icon.COPY}
          onClick={handleCopyPhoneNumber}
          variant={ButtonVariant.GHOST}
          color={ButtonColor.SECONDARY}
          tooltip="Copy phone number"
        />
        <TextButton text="Call now" onClick={handleCallNow} />
      </div>
    </div>
  );
};

export default ProspectHotlineItem;
