import { useCallback, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { useShareCall } from '../../../hooks';
import { ComponentSize, DropdownPlacement } from '../../../types';
import { formatSecondsToDuration } from '../../../utils';
import {
  ButtonColor,
  Checkbox,
  Dropdown,
  DropdownContent,
  DropdownTrigger,
  Icon,
  IconButton,
  Icons,
  TextButton,
  TextInput,
  Typography,
  TypographySize,
} from '../../shared';

const DROPDOWN_TEXT_INPUT_WIDTH = 300;

interface ShareButtonProps {
  callSid: string;
  currentTime: number;
  isCompact: boolean;
  waveSurfer: WaveSurfer;
}

const ShareButton = ({ callSid, currentTime, isCompact, waveSurfer }: ShareButtonProps) => {
  const [copyIcon, setCopyIcon] = useState(Icon.LINK);
  // State to control the Share dropdown open/close state.
  const [isShareDropdownOpen, setIsShareDropdownOpen] = useState(false);
  // State to control the Start Time checkbox state.
  const [isStartTimeChecked, setIsStartTimeChecked] = useState(false);

  const { shareUrl, onShare } = useShareCall(callSid, isStartTimeChecked ? currentTime : undefined);

  const toggleShareDropdown = useCallback(() => {
    if (isShareDropdownOpen) {
      // Handle closing the Share dropdown and resetting the Start Time checkbox state.
      setIsShareDropdownOpen(false);
      setIsStartTimeChecked(false);
    } else {
      // Handle opening the Share dropdown and pausing the audio if it is currently playing.
      setIsShareDropdownOpen(true);
      if (waveSurfer.isPlaying()) {
        waveSurfer.pause();
      }
    }
  }, [isShareDropdownOpen, waveSurfer, setIsShareDropdownOpen, setIsStartTimeChecked]);

  // Handles copying the Call URL to the clipboard.
  const copyUrlToClipboard = useCallback(() => {
    onShare();
    setCopyIcon(Icon.CHECK);
    setTimeout(() => {
      setCopyIcon(Icon.LINK);
    }, 2000);
  }, [onShare, setCopyIcon]);

  const action = {
    icon: Icon.SHARE,
    text: 'Share',
    onClick: toggleShareDropdown,
    active: isShareDropdownOpen,
    color: ButtonColor.SECONDARY,
  };

  return (
    <Dropdown open={isShareDropdownOpen} onOpenChange={toggleShareDropdown} placement={DropdownPlacement.TOP_END}>
      <DropdownTrigger>
        {isCompact && (
          <IconButton
            icon={action.icon}
            onClick={action.onClick}
            color={action.color}
            active={action.active}
            tooltip={action.text}
          />
        )}
        {!isCompact && (
          <TextButton
            text={action.text}
            startIcon={action.icon}
            onClick={action.onClick}
            active={action.active}
            color={action.color}
          />
        )}
      </DropdownTrigger>
      <DropdownContent className="flex flex-col gap-2">
        <TextInput
          value={shareUrl}
          width={DROPDOWN_TEXT_INPUT_WIDTH}
          size={ComponentSize.X_SMALL}
          disabled
          endElement={
            <Icons
              icon={copyIcon}
              size={ComponentSize.X_SMALL}
              tooltip={copyIcon === Icon.LINK ? 'Copy URL to clipboard' : 'Copied!'}
              onClick={copyUrlToClipboard}
            />
          }
        />
        <div className="flex items-center gap-1">
          <Checkbox checked={isStartTimeChecked} setChecked={setIsStartTimeChecked} size={ComponentSize.X_SMALL} />
          <Typography size={TypographySize.CAPTION}>
            Start at&nbsp;
            <span className="text-neutral-content">{formatSecondsToDuration(currentTime)}</span>
          </Typography>
        </div>
      </DropdownContent>
    </Dropdown>
  );
};

export default ShareButton;
