import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import {
  closeCreateProspectModal,
  openGongCallsModal,
  openSettingsModal,
  SettingsModalTabs,
} from '../../../../../redux/reducers';
import { TextColor } from '../../../../../types';
import { ButtonColor, TextButton, Typography, TypographySize } from '../../../../shared';

const AITwinGong = () => {
  const dispatch = useAppDispatch();
  const { organization } = useAppSelector((state) => state.auth);
  const isGongConnected = !!organization?.gongAuth;

  const buttonText = isGongConnected ? 'Select call' : 'Connect';
  const description = isGongConnected ? 'Import calls from Gong.' : 'Your organization hasn’t connected to Gong yet.';

  const handleButtonClick = () => {
    dispatch(closeCreateProspectModal());
    if (isGongConnected) {
      dispatch(openGongCallsModal());
    } else {
      dispatch(openSettingsModal(SettingsModalTabs.INTEGRATIONS));
    }
  };

  return (
    <div className="flex justify-between gap-4">
      <Typography size={TypographySize.H5} color={TextColor.SECONDARY}>
        {description}
      </Typography>
      <TextButton text={buttonText} color={ButtonColor.GONG} onClick={handleButtonClick} />
    </div>
  );
};

export default AITwinGong;
