import { useCallback } from 'react';
import { useAppDispatch, useHandleApiResponse } from '../../../hooks';
import { closeUnflagConfirmModal, UnflagConfirmModalState, updateCallFlagged } from '../../../redux/reducers';
import { useUnflagCallMutation } from '../../../services';
import { StatusColor } from '../../../types';
import { Icon, Icons } from '../../shared';
import ConfirmModal from '../ConfirmModal';

const UNFLAG_ERROR_MSG = 'Error unflagging call';

const UnflagConfirmModal = ({ callSid }: UnflagConfirmModalState) => {
  const dispatch = useAppDispatch();

  const handleApiResponse = useHandleApiResponse();

  const [unflagCall, { isLoading }] = useUnflagCallMutation();

  const onSuccess = useCallback(() => {
    // Update the call history store with the unflagged call to immediately reflect the changes.
    dispatch(updateCallFlagged({ callSid, isFlaggedToUser: false }));
    dispatch(closeUnflagConfirmModal());
  }, [dispatch, callSid]);

  const handleUnflagCall = useCallback(async () => {
    try {
      const response = await unflagCall(callSid);
      handleApiResponse({ response, errorMsg: UNFLAG_ERROR_MSG, onSuccess });
    } catch (error) {
      console.error(`${UNFLAG_ERROR_MSG}: `, error);
    }
  }, [callSid, unflagCall, dispatch, handleApiResponse, onSuccess]);

  return (
    <ConfirmModal
      isOpen
      icon={<Icons icon={Icon.FLAG} color={StatusColor.WARNING} fill />}
      title="Unflag call"
      confirmText="Are you sure you want to unflag this call? This action cannot be undone."
      buttonText="Unflag"
      setIsOpen={(open) => {
        if (!open) {
          dispatch(closeUnflagConfirmModal());
        }
      }}
      onConfirm={handleUnflagCall}
      isLoading={isLoading}
    />
  );
};

export default UnflagConfirmModal;
