import { Avatar, Badge, BadgeType, Icon, Typography } from '../../../../components';
import { ComponentSize, TextColor } from '../../../../types';

interface ScoredByInfoProps {
  isScoredByAi: boolean;
  user: {
    name?: string;
    picture?: string;
  };
}

const ScoredByInfo = ({ isScoredByAi, user }: ScoredByInfoProps) => {
  return (
    <div className="flex gap-2">
      <Typography color={TextColor.SECONDARY}>Scored by:</Typography>
      {isScoredByAi && <Badge label="AI" type={BadgeType.AI} icon={Icon.WAND} />}
      {!isScoredByAi && (
        <>
          <Avatar size={ComponentSize.X_SMALL} label={user.name} imgSrc={user.picture} />
          <Typography color={TextColor.SECONDARY}>{user.name}</Typography>
        </>
      )}
    </div>
  );
};

export default ScoredByInfo;
