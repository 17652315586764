import { AlertType, ButtonColor } from '../../shared';

import { ComponentSize } from '../../../types';

import { TextButton } from '../../shared';

import { useCallback, useMemo } from 'react';
import { useAppDispatch, useHandleApiResponse, useToast } from '../../../hooks';
import { closeRequestReviewModal, updateCallFlagged } from '../../../redux/reducers';
import { useFlagCallMutation } from '../../../services';
import { conditionalObject } from '../../../utils';
import { ButtonGroup } from '../../shared';

const FLAG_CALL_ERROR_MSG = 'Failed to flag call';

interface RequestReviewModalActionsProps {
  /** The callSid of the call to flag. */
  callSid?: string;
  /** The currently flagged users for the call. */
  flaggedUserIds?: string[];
  /** The selected users to notify. */
  selectedUsers?: { userId: string; notify: boolean }[];
}

const RequestReviewModalActions = ({ callSid, flaggedUserIds, selectedUsers }: RequestReviewModalActionsProps) => {
  const dispatch = useAppDispatch();

  const [flagCall, { isLoading: isFlaggingCall }] = useFlagCallMutation();

  const { showToast } = useToast();
  const handleApiResponse = useHandleApiResponse(true);

  // If no managers are notified, that means some managers were removed from review, since we only enable
  // the "Send notification" button if there are any changes to the currently flagged managers.
  const numManagersToNotify = useMemo(() => selectedUsers?.filter((u) => u.notify).length, [selectedUsers]);

  const onError = useCallback(() => {
    showToast({
      message: numManagersToNotify
        ? 'Failed to notify managers. Please try again.'
        : 'Failed to remove managers from review. Please try again.',
      type: AlertType.ERROR,
    });
  }, [numManagersToNotify, showToast]);

  const onSuccess = useCallback(() => {
    if (!callSid || !selectedUsers) return;

    // Show a toast with content dependent on the number of managers notified.
    showToast({
      ...conditionalObject(!!numManagersToNotify, { title: 'Managers notified for review' }),
      message: numManagersToNotify
        ? `Notification sent to ${numManagersToNotify} ${numManagersToNotify === 1 ? 'person' : 'people'}.`
        : 'Managers removed from review',
      type: AlertType.INFO,
    });

    // Update the call history store with the flagged users to immediately reflect the changes.
    const flaggedUserIds = selectedUsers.map((u) => u.userId);
    dispatch(updateCallFlagged({ callSid, flaggedUserIds }));

    // Close the modal.
    dispatch(closeRequestReviewModal());
  }, [callSid, numManagersToNotify, selectedUsers, dispatch, showToast]);

  const handleFlagCall = useCallback(async () => {
    if (!callSid || !selectedUsers) return;
    try {
      const response = await flagCall({ callSid, flaggedUsers: selectedUsers });
      handleApiResponse({
        response,
        errorMsg: FLAG_CALL_ERROR_MSG,
        onError,
        onSuccess,
      });
    } catch (error) {
      console.error(FLAG_CALL_ERROR_MSG, error);
    }
  }, [flagCall, callSid, selectedUsers, dispatch, handleApiResponse, onError, onSuccess]);

  // Disable "Send notification" button if no changes are made to the currently flagged users.
  const isNotifyDisabled = useMemo(() => {
    if (flaggedUserIds?.length !== selectedUsers?.length) return false;
    if (selectedUsers?.find((user) => user.notify)) return false;
    return flaggedUserIds?.every((val, idx) => val === selectedUsers?.[idx].userId);
  }, [flaggedUserIds, selectedUsers]);

  return (
    <ButtonGroup size={ComponentSize.MEDIUM}>
      <TextButton
        text="Send notification"
        onClick={handleFlagCall}
        color={ButtonColor.PRIMARY}
        loading={isFlaggingCall}
        disabled={isNotifyDisabled}
      />
      <TextButton
        disabled={isFlaggingCall}
        text="Cancel"
        onClick={() => {
          dispatch(closeRequestReviewModal());
        }}
      />
    </ButtonGroup>
  );
};

export default RequestReviewModalActions;
