import { User as Auth0User } from '@auth0/auth0-react';
import { PaginationState } from '@tanstack/react-table';
import { Pagination } from './api.types';
import { CallerInfo } from './app.types';
import { Candidate } from './candidates.types';
import { DBPracticeProspect, PracticeProspect, ProspectColumnData } from './prospect.types';
import { CustomSortingState } from './sorting.types';
import { DateRangeType } from './date.types';

// -- ENUMS --
export enum CallProcessingStatus {
  PROCESSING = 'PROCESSING',
  PROCESSED = 'PROCESSED',
  PROCESSING_FAILED = 'PROCESSING_FAILED',
}

export enum CallSortingFilters {
  CALL_DURATION = 'callDuration',
  CALLER = 'caller',
  PROSPECT = 'prospect',
  START_TIME = 'startTime',
}

export enum CallSpeaker {
  AGENT = 'AGENT',
  CUSTOMER = 'CUSTOMER',
}

export enum CallMethod {
  WEB_CALL = 'WEB_CALL',
  TELEPHONY = 'TELEPHONY',
}

type WordBase = {
  word: string;
  start: number;
  end: number;
  confidence: number;
  punctuated_word?: string;
  speaker?: number;
  speaker_confidence?: number;
};

export type Utterance = {
  start: number;
  end: number;
  transcript: string;
  speaker: CallSpeaker;
  channel: number;
  id: string;
  words: WordBase[];
};

// -- TYPES --
export type CallSpeechCategory = {
  id: string;
  callSid: string;
  summary: string;
  speaker: CallSpeaker;
  category: string;
  label: string;
  importanceConfidence: number;
  startTime: number;
  endTime: number;
  createdAt: Date;
  updatedAt: Date;
};

export interface CallsPayload {
  // Filters
  incomingPhoneNumber?: string[];
  personaId?: string[];
  startTime?: DateRangeType[];
  tags?: string[];
  userId?: string[];
  isFlagged?: boolean;
  // Controls
  pagination?: PaginationState;
  sorting?: CustomSortingState;
}

type CallChapter = {
  id: string;
  callSid: string;
  title: string;
  startTime: number;
  endTime: number;
};

type CallWithoutProspect = {
  callSid: string;
  callStatus: string; // "started" or "ended"
  callType: string; // "inbound" or "outbound"
  incomingPhoneNumber?: string;
  orgId: string;
  personaId: string;
  receivingPhoneNumber: string;
  startTime: Date;
  audioPath?: string;
  callDuration?: number; // Duration in seconds
  endedReason?: string;
  endTime?: Date;
  transcriptionPath?: string;
  user?: Auth0User;
  userId?: string;
  candidate?: Candidate;
  candidateId?: string;

  // Processing details
  fillerWordsCount?: number;
  longestMonologueDuration?: number;
  longestMonologueStart?: number;
  processingStatus?: CallProcessingStatus;
  summary?: string;
  talkTime?: number;
  wordsCount?: number;

  callChapters?: CallChapter[];
  callSpeechCategories?: CallSpeechCategory[];

  // Flagging details
  flaggedUserIds?: string[];
  isFlaggedToUser?: boolean;
};

export type Call = CallWithoutProspect & {
  practiceProspect: PracticeProspect;
};

type CallWithDBPracticeProspect = CallWithoutProspect & {
  practiceProspect: DBPracticeProspect;
};

export type CallResponse = {
  data: {
    call: CallWithDBPracticeProspect;
  };
};

export type CallsResponse = {
  data: {
    calls: CallWithDBPracticeProspect[];
    pagination: Pagination;
  };
};

export type FlaggedUser = {
  userId: string;
  notify: boolean;
};

export type FlagCallPayload = {
  callSid: string;
  flaggedUsers: FlaggedUser[];
};

export type CallColumn = {
  callDate: Date;
  caller: CallerInfo;
  duration: number;
  isFlagged: boolean;
  prospect: ProspectColumnData;
};
