import * as RadixTabs from '@radix-ui/react-tabs';
import clsx from 'clsx';
import { Tab, TabsType } from './Tabs.types';

interface TabsContentProps {
  tabs: Tab[];
  type: TabsType;
}

const TabsContent = ({ tabs, type }: TabsContentProps) => {
  const getLiftedClasses = (index: number) => {
    let classes = 'mt-[-1px] rounded-b-lg border border-base-100 bg-base-0 p-4';

    if (index === 0) {
      classes += ' rounded-tr-lg';
    } else {
      classes += ' rounded-t-lg';
    }

    return classes;
  };

  return (
    <>
      {tabs.map(
        ({ content, id }, index) =>
          content && (
            <RadixTabs.Content
              key={id}
              value={id}
              className={clsx('h-full w-full', type === TabsType.LIFTED && getLiftedClasses(index))}
            >
              {content}
            </RadixTabs.Content>
          )
      )}
    </>
  );
};

export default TabsContent;
