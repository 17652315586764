import { Icons, TextButton, Typography } from '../../components';

import { Icon } from '../../components';
import { ComponentSize } from '../../types';

interface AnalyticsEmptyStateProps {
  text: string;
  showCallButton?: boolean;
  onButtonClick?: () => void;
}

const AnalyticsEmptyState = ({ text, showCallButton, onButtonClick }: AnalyticsEmptyStateProps) => {
  return (
    <div className="flex h-full flex-col items-center justify-center gap-4">
      <Icons icon={Icon.BAR_CHART} size={ComponentSize.LARGE} />
      <Typography>{text}</Typography>
      {showCallButton && <TextButton text="Call now" onClick={onButtonClick} />}
    </div>
  );
};

export default AnalyticsEmptyState;
