import WaveSurfer from 'wavesurfer.js';
import { useWindowResize } from '../../../hooks';
import { Call, CallProcessingStatus } from '../../../types';
import CommentButton from './CommentButton';
import FlagButton from './FlagButton';
import ShareButton from './ShareButton';
import SpeedButton from './SpeedButton';

// Threshold below which media player actions are not displayed as full text buttons.
const MEDIA_PLAYER_ACTIONS_THRESHOLD = 1200;

interface MediaPlayerActionsProps {
  call: Call;
  currentTime: number;
  waveSurfer: WaveSurfer;
  processingStatus?: CallProcessingStatus;
}

const MediaPlayerActions = ({ call, waveSurfer, processingStatus, currentTime }: MediaPlayerActionsProps) => {
  const windowWidth = useWindowResize();
  // Whether to display media player actions as compact icon buttons.
  const isCompact = windowWidth <= MEDIA_PLAYER_ACTIONS_THRESHOLD;

  return (
    <div className="flex gap-2">
      <SpeedButton waveSurfer={waveSurfer} />
      <CommentButton callSid={call.callSid} waveSurfer={waveSurfer} currentTime={currentTime} isCompact={isCompact} />
      <FlagButton call={call} isCompact={isCompact} />
      {/* Only show Share if call processing has completed. */}
      {processingStatus === CallProcessingStatus.PROCESSED && (
        <ShareButton callSid={call.callSid} waveSurfer={waveSurfer} currentTime={currentTime} isCompact={isCompact} />
      )}
    </div>
  );
};

export default MediaPlayerActions;
