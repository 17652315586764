import { ChangeEvent } from 'react';
import {
  ButtonColor,
  ButtonVariant,
  Icon,
  IconButton,
  TextInput,
  Typography,
  TypographySize,
} from '../../../../components';
import { TextColor } from '../../../../types';

interface ScenarioListItemProps {
  index: number;
  items: { value: string; isUserModified?: boolean }[];
  value: string;
  setItems: (items: { value: string; isUserModified?: boolean }[]) => void;
}

const ScenarioListItem = ({ index, items, value, setItems }: ScenarioListItemProps) => {
  // Updates the item at the given index.
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newItems = [...items];
    const newItem = e.target.value;
    newItems[index] = { value: newItem, isUserModified: !!newItem };
    setItems(newItems);
  };

  // Deletes the item at the given index.
  const onDelete = () => {
    setItems(items.filter((_, i) => i !== index));
  };

  return (
    <div key={index} className="flex items-center gap-2">
      <Typography color={TextColor.SECONDARY} size={TypographySize.CAPTION} className="select-none">
        {index + 1}.
      </Typography>
      <TextInput value={value} onChange={onChange} />
      <IconButton color={ButtonColor.DESTRUCTIVE} icon={Icon.TRASH} variant={ButtonVariant.GHOST} onClick={onDelete} />
    </div>
  );
};

export default ScenarioListItem;
