import {
  AlertCircle,
  ArchiveX,
  ArrowDownWideNarrow,
  ArrowUpDown,
  ArrowUpWideNarrow,
  BarChart,
  Blocks,
  Building2,
  Captions,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronsUpDown,
  CircleCheck,
  CircleHelp,
  CircleX,
  Copy,
  CopyPlus,
  EllipsisVertical,
  ExternalLink,
  Flag,
  Gauge,
  GripVertical,
  Handshake,
  Headphones,
  History,
  Info,
  Laptop,
  Link,
  Lock,
  LogOut,
  Maximize2,
  MessageCircle,
  Mic,
  MicOff,
  Minimize2,
  Minus,
  Pause,
  Pencil,
  Phone,
  PhoneOutgoing,
  Play,
  Plus,
  RotateCcw,
  RotateCw,
  Search,
  Send,
  Settings,
  Share2,
  TrafficCone,
  Trash2,
  TriangleAlert,
  Upload,
  UserCheck,
  UserPlus,
  UserRound,
  UserRoundSearch,
  WandSparkles,
  WifiOff,
  X,
  Zap,
} from 'lucide-react';
import { ReactComponent as GongIcon } from '../../../assets/gong-icon.svg';
import { ReactComponent as FlagCircleOutlined } from '../../../assets/mui-flag-icon.svg';
import { ComponentSize, SemanticColor, StatusColor, TextColor } from '../../../types';
import { Icon, IconColor } from './Icons.types';

export const ICON_MAP = {
  [Icon.ARCHIVE]: ArchiveX,
  [Icon.ARROW_SORT_ASC]: ArrowUpWideNarrow,
  [Icon.ARROW_SORT_DESC]: ArrowDownWideNarrow,
  [Icon.ARROW_SORT_NONE]: ArrowUpDown,
  [Icon.BAR_CHART]: BarChart,
  [Icon.CAPTION]: Captions,
  [Icon.CHECK]: Check,
  [Icon.CHEVRON_DOWN]: ChevronDown,
  [Icon.CHEVRON_LEFT]: ChevronLeft,
  [Icon.CHEVRON_RIGHT]: ChevronRight,
  [Icon.CHEVRON_UP]: ChevronUp,
  [Icon.CHEVRONS_UP_DOWN]: ChevronsUpDown,
  [Icon.CIRCLE_ALERT]: AlertCircle,
  [Icon.CIRCLE_CHECK]: CircleCheck,
  [Icon.CIRCLE_HELP]: CircleHelp,
  [Icon.CIRCLE_X]: CircleX,
  [Icon.CLOSE]: X,
  [Icon.COMMENT]: MessageCircle,
  [Icon.COPY]: Copy,
  [Icon.DUPLICATE]: CopyPlus,
  [Icon.EDIT]: Pencil,
  [Icon.ELLIPSIS_VERTICAL]: EllipsisVertical,
  [Icon.FAST_FORWARD]: RotateCw,
  [Icon.FLAG]: FlagCircleOutlined,
  [Icon.GRIP_VERTICAL]: GripVertical,
  [Icon.GONG]: GongIcon,
  [Icon.HANDSHAKE]: Handshake,
  [Icon.HEADPHONES]: Headphones,
  [Icon.HISTORY]: History,
  [Icon.INFO]: Info,
  [Icon.INTEGRATIONS]: Blocks,
  [Icon.LAPTOP]: Laptop,
  [Icon.LINK]: Link,
  [Icon.LOCK]: Lock,
  [Icon.LOG_OUT]: LogOut,
  [Icon.MAXIMIZE]: Maximize2,
  [Icon.MINIMIZE]: Minimize2,
  [Icon.MIC_ON]: Mic,
  [Icon.MIC_OFF]: MicOff,
  [Icon.MINUS]: Minus,
  [Icon.OPEN]: ExternalLink,
  [Icon.ORGANIZATION]: Building2,
  [Icon.PAUSE]: Pause,
  [Icon.PLAY]: Play,
  [Icon.PHONE]: Phone,
  [Icon.PHONE_OUTGOING]: PhoneOutgoing,
  [Icon.PLUS]: Plus,
  [Icon.REPORT]: Flag,
  [Icon.REWIND]: RotateCcw,
  [Icon.SEARCH]: Search,
  [Icon.SEND]: Send,
  [Icon.SETTINGS]: Settings,
  [Icon.SHARE]: Share2,
  [Icon.SPEED]: Gauge,
  [Icon.TRASH]: Trash2,
  [Icon.TRIANGLE_ALERT]: TriangleAlert,
  [Icon.USER]: UserRound,
  [Icon.USER_CHECK]: UserCheck,
  [Icon.USER_PLUS]: UserPlus,
  [Icon.USER_SEARCH]: UserRoundSearch,
  [Icon.WIFI_OFF]: WifiOff,
  [Icon.UPLOAD]: Upload,
  [Icon.TRAFFIC_CONE]: TrafficCone,
  [Icon.WAND]: WandSparkles,
  [Icon.ZEUS]: Zap,
};

export const ICON_SIZE: Record<ComponentSize, number> = {
  [ComponentSize.X_SMALL]: 14,
  [ComponentSize.SMALL]: 16,
  [ComponentSize.MEDIUM]: 20,
  [ComponentSize.LARGE]: 24,
};

/** Maps icon colors to stroke color classes. */
export const ICON_COLOR_TO_STROKE_COLOR: Record<IconColor, string> = {
  [TextColor.DESTRUCTIVE]: 'stroke-error-content',
  [TextColor.LINK]: 'stroke-accent',
  [TextColor.PRIMARY]: 'stroke-neutral',
  [TextColor.SECONDARY]: 'stroke-neutral-content',
  [TextColor.SUCCESS]: 'stroke-success-content',
  [TextColor.TERTIARY]: 'stroke-base-300',
  [TextColor.WHITE]: 'stroke-white',
  [TextColor.AI]: 'stroke-ai',
  [StatusColor.ERROR]: 'stroke-error',
  [StatusColor.INFO]: 'stroke-info',
  [StatusColor.SUCCESS]: 'stroke-success',
  [StatusColor.WARNING]: 'stroke-warning',
  [SemanticColor.PRIMARY]: 'stroke-primary',
  [SemanticColor.SECONDARY]: 'stroke-secondary',
  [SemanticColor.ACCENT]: 'stroke-accent',
};

/** Maps icon colors to fill color classes. */
export const ICON_COLOR_TO_FILL_COLOR: Record<IconColor, string> = {
  [TextColor.DESTRUCTIVE]: 'fill-error-content',
  [TextColor.LINK]: 'fill-accent',
  [TextColor.PRIMARY]: 'fill-neutral',
  [TextColor.SECONDARY]: 'fill-neutral-content',
  [TextColor.SUCCESS]: 'fill-success-content',
  [TextColor.TERTIARY]: 'fill-base-300',
  [TextColor.WHITE]: 'fill-white',
  [TextColor.AI]: 'fill-ai',
  [StatusColor.ERROR]: 'fill-error',
  [StatusColor.INFO]: 'fill-info',
  [StatusColor.SUCCESS]: 'fill-success',
  [StatusColor.WARNING]: 'fill-warning',
  [SemanticColor.PRIMARY]: 'fill-primary',
  [SemanticColor.SECONDARY]: 'fill-secondary',
  [SemanticColor.ACCENT]: 'fill-accent',
};
