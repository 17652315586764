import clsx from 'clsx';
import { BASE_COLOR_TO_BG_COLOR, STATUS_COLOR_TO_BG_COLOR, TAG_COLOR_TO_BG_COLOR } from '../../../constants';
import { BaseColor, StatusColor, TagColor } from '../../../types';
import {
  conditionalObject,
  getTooltipContent,
  getTooltipPlacement,
  isBaseColor,
  isStatusColor,
  isTagColor,
} from '../../../utils';
import { Tooltip, TooltipContent, TooltipTrigger } from '../Tooltip';
import { BadgeDotProps } from './BadgeDot.types';

const BadgeDot = ({ color, tooltip, onClick }: BadgeDotProps) => {
  const handleOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      e.stopPropagation();
      onClick();
    }
  };

  return (
    <Tooltip placement={getTooltipPlacement(tooltip)}>
      <TooltipTrigger>
        <div
          className={clsx(
            'h-2 w-2 rounded-full',
            onClick && 'cursor-pointer',
            isBaseColor(color) && BASE_COLOR_TO_BG_COLOR[color as BaseColor],
            isTagColor(color) && TAG_COLOR_TO_BG_COLOR[color as TagColor],
            isStatusColor(color) && STATUS_COLOR_TO_BG_COLOR[color as StatusColor]
          )}
          style={{
            ...conditionalObject(!isBaseColor(color) && !isTagColor(color) && !isStatusColor(color), {
              backgroundColor: color,
            }),
          }}
          onClick={handleOnClick}
        />
      </TooltipTrigger>
      <TooltipContent>{getTooltipContent(tooltip)}</TooltipContent>
    </Tooltip>
  );
};

export default BadgeDot;
