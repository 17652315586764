import { Cell, Getter, PaginationState, Row } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ButtonColor,
  ButtonVariant,
  DataTableColumnDef,
  DataTableWithActions,
  Icon,
  IconButton,
  MediaPlayer,
  SortableHeader,
  Typography,
  TypographySize,
  UserBadge,
} from '../../components';
import { DATE_COLUMN_WIDTH, PAGINATION_PAGE_SIZE } from '../../constants';
import { useAppDispatch, useAppSelector, useFeatureFlag, useGetFiltersFromParams } from '../../hooks';
import { setCalls } from '../../redux/reducers';
import { useGetCallsMutation } from '../../services';
import {
  CallColumn,
  CallerInfo,
  CallHistoryFilters,
  CallSortingFilters,
  ComponentSize,
  CustomSortingState,
  DateFormat,
  LD_FeatureFlags,
  ProspectColumnData,
  Roles,
  SortingOrder,
  TextColor,
  TimeFormat,
} from '../../types';
import {
  conditionalArray,
  conditionalObject,
  formatSecondsToDuration,
  getCallerInfo,
  getDateFilter,
} from '../../utils';
import CallDetailsDrawer from './CallDetailsDrawer';
import CallHistoryFooter from './CallHistoryFooter';
import FlaggedIndicator from './FlaggedIndicator';
import useCallActions from './useCallActions';

const DURATION_COLUMN_WIDTH = '86px';
const CALLER_COLUMN_WIDTH = '250px';
const FLAGGED_COLUMN_WIDTH = '24px';

/**
 * Gets the caller filter based on the caller value.
 * If the caller is a phone number, filter by incoming phone number.
 * If the caller is a user ID, filter by user ID.
 */
const parseCallerFilter = (callers?: string[]) => {
  if (!callers) return {};

  const phoneNumbers: string[] = [];
  const userIds: string[] = [];

  callers.forEach((caller) => {
    if (caller.startsWith('+')) {
      phoneNumbers.push(caller);
    } else {
      userIds.push(caller);
    }
  });

  return {
    ...(phoneNumbers.length > 0 && { incomingPhoneNumber: phoneNumbers }),
    ...(userIds.length > 0 && { userId: userIds }),
  };
};

const CallHistoryPage = () => {
  // State to track the active row.
  const [activeRowIndex, setActiveRowIndex] = useState<number | undefined>(undefined);
  // State to track the clicked row.
  const [clickedRowIndex, setClickedRowIndex] = useState<number | undefined>(undefined);
  // State to track the hovered row.
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | undefined>(undefined);
  // State for pagination settings.
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: PAGINATION_PAGE_SIZE,
  });
  // State for sorting settings.
  const [sorting, setSorting] = useState<CustomSortingState>({
    sortBy: CallSortingFilters.START_TIME,
    sortOrder: SortingOrder.DESC,
  });

  // Redux
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const { calls, currentCallDetails } = useAppSelector((state) => state.callHistory);
  const isSalesRep = useMemo(() => user?.role === Roles.SALES_REP, [user?.role]);

  // Mutations
  // Fetch calls with loading state management.
  const [getCalls, { data, isLoading }] = useGetCallsMutation();
  const totalPages = data?.pagination.totalPages || 0;

  // Ref to the media player element.
  const mediaPlayerRef = useRef<HTMLDivElement | null>(null);

  // Custom hooks
  const filters: CallHistoryFilters = useGetFiltersFromParams();

  const clickedCall = useMemo(() => {
    if (clickedRowIndex === undefined) return;
    return calls[clickedRowIndex];
  }, [calls, clickedRowIndex]);

  const actions = useCallActions(() => setClickedRowIndex(undefined), clickedCall);
  const flaggingFF = useFeatureFlag(LD_FeatureFlags.RELEASE_FLAGGING_CALLS);

  // Reset to page 1 when filters change.
  useEffect(() => {
    if (pagination.pageIndex !== 1) {
      setPagination((prev) => ({ ...prev, pageIndex: 1 }));
    }
  }, [filters, setPagination]);

  const fetchCalls = useCallback(() => {
    getCalls({
      isFlagged: filters.flagged,
      personaId: filters.prospect,
      startTime: getDateFilter(filters.callDate),
      tags: filters.tags,
      pagination,
      sorting,
      ...conditionalObject(!isSalesRep, parseCallerFilter(filters.caller)),
    });
  }, [isSalesRep, filters, pagination, sorting]);

  // Fetch calls whenever filters or pagination settings change.
  useEffect(() => {
    fetchCalls();
  }, [fetchCalls]);

  // This effect sets the list of calls for the Call History Page in state.
  // It allows for optimistic updates to the state instead of refetching from the server.
  useEffect(() => {
    if (isLoading) return;
    dispatch(setCalls(data?.calls || []));
    return () => {
      dispatch(setCalls([]));
    };
  }, [data?.calls, isLoading, dispatch, setCalls]);

  // Set the active row index to the call that is currently being played.
  useEffect(() => {
    if (currentCallDetails) {
      const index = calls.findIndex((call) => call.callSid === currentCallDetails.callSid);
      if (index === -1) {
        // If the call is not in the list, reset the active row index.
        // Happens when the user filters the calls and the call currently being played is no longer in the list.
        setActiveRowIndex(undefined);
      } else if (index !== activeRowIndex) {
        // If the call is in the list and the active row index changed,
        // update the active row index.
        setActiveRowIndex(index);
      }
    } else if (activeRowIndex !== undefined) {
      // Reset the active row index when the media player closes.
      setActiveRowIndex(undefined);
    }

    // We purposely don't include activeRowIndex in the dependency array
    // because we only want to run this effect when the callSid or filters change.
  }, [currentCallDetails, calls, filters, setActiveRowIndex]);

  if (!user) return null;

  // Transform the fetched calls data to match the table's expected row structure.
  const parsedCalls: CallColumn[] = calls.map((call) => {
    // Checks if the call is either flagged TO this user or flagged BY this user.
    // Used to show the flag indicator in the table row.
    const isFlagged = !!call.isFlaggedToUser || !!call.flaggedUserIds?.length;
    return {
      callDate: call.startTime,
      caller: getCallerInfo(call, user),
      duration: call.callDuration || 0,
      isFlagged,
      prospect: {
        name: `${call.practiceProspect.firstName} ${call.practiceProspect.lastName}`,
        jobTitle: call.practiceProspect.jobTitle,
        company: call.practiceProspect.accountName,
      },
    };
  });

  const renderSortableHeader = useCallback(
    (sortingId: CallSortingFilters, title: string) => (
      <SortableHeader title={title} sorting={sorting} setSorting={setSorting} sortingId={sortingId} />
    ),
    [sorting, setSorting]
  );

  // Define columns for the data table.
  const columns: DataTableColumnDef<CallColumn>[] = useMemo(
    () => [
      ...conditionalArray(flaggingFF, {
        accessorKey: 'isFlagged',
        header: () => '',
        width: FLAGGED_COLUMN_WIDTH,
        cell: ({ cell, row }: { cell: Cell<CallColumn, unknown>; row: Row<CallColumn> }) => {
          if (!cell.getValue()) return null;
          const call = calls[row.index];
          const isActive =
            hoveredRowIndex === row.index || activeRowIndex === row.index || clickedRowIndex === row.index;
          return <FlaggedIndicator call={call} isActive={isActive} />;
        },
      }),
      {
        accessorKey: 'duration',
        header: () => renderSortableHeader(CallSortingFilters.CALL_DURATION, 'Duration'),
        width: DURATION_COLUMN_WIDTH,
        cell: ({ getValue }: { getValue: Getter<number> }) => {
          const duration = getValue();
          return formatSecondsToDuration(duration);
        },
      },
      // Conditionally include the "Caller" column if the user is not a sales representative.
      // This is because a sales rep can only see their own call history.
      ...conditionalArray(!isSalesRep, {
        accessorKey: 'caller',
        header: () => renderSortableHeader(CallSortingFilters.CALLER, 'Caller'),
        width: CALLER_COLUMN_WIDTH,
        cell: ({ getValue }: { getValue: Getter<CallerInfo | undefined> }) => {
          const caller = getValue();
          if (!caller) return 'N/A';
          return <UserBadge title={caller.name} imgSrc={caller.picture} hideAvatarIfNoImgSrc />;
        },
      }),
      {
        accessorKey: 'prospect',
        header: () => renderSortableHeader(CallSortingFilters.PROSPECT, 'Prospect'),
        cell: ({ getValue }: { getValue: Getter<ProspectColumnData> }) => {
          const { name, jobTitle, company } = getValue();
          return <UserBadge title={name} subtitle={`${company} | ${jobTitle}`} />;
        },
      },
      {
        accessorKey: 'callDate',
        header: () => renderSortableHeader(CallSortingFilters.START_TIME, 'Call date'),
        width: DATE_COLUMN_WIDTH,
        cell: ({ getValue }: { getValue: Getter<Date> }) => {
          const date = new Date(getValue());
          return (
            <div className="flex flex-col gap-1">
              <Typography color={TextColor.SECONDARY} size={TypographySize.CAPTION}>
                {dayjs(date).format(TimeFormat.SHORT_TIME)}
              </Typography>
              <Typography size={TypographySize.CAPTION}>{dayjs(date).format(DateFormat.MONTH_DAY)}</Typography>
            </div>
          );
        },
      },
    ],
    [calls, activeRowIndex, clickedRowIndex, flaggingFF, hoveredRowIndex, isSalesRep, renderSortableHeader]
  );

  return (
    <>
      <div className="flex flex-col items-center gap-8">
        <div className="flex flex-col gap-2">
          <IconButton
            icon={Icon.FAST_FORWARD}
            variant={ButtonVariant.GHOST}
            color={ButtonColor.SECONDARY}
            onClick={fetchCalls}
            tooltip="Refresh"
            size={ComponentSize.X_SMALL}
          />
          <DataTableWithActions
            actions={actions}
            columns={columns}
            isLoading={isLoading}
            data={parsedCalls}
            activeRowIndex={activeRowIndex}
            clickedRowIndex={clickedRowIndex}
            setClickedRowIndex={setClickedRowIndex}
            setHoveredRowIndex={setHoveredRowIndex}
            paginationControls={{ pagination, totalPages, setPagination }}
          />
        </div>
        <CallHistoryFooter />
      </div>

      {/* Call Details Drawer */}
      <CallDetailsDrawer mediaPlayerRef={mediaPlayerRef} />
      {/* MediaPlayer Drawer */}
      <MediaPlayer ref={mediaPlayerRef} />
    </>
  );
};

export default CallHistoryPage;
