import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes, PROSPECT_TYPE_TO_LABEL } from '../../../constants';
import { useGetOrganizationsQuery } from '../../../services';
import { ProspectType, TextColor } from '../../../types';
import { parseOrgToOption } from '../../../utils';
import { Dialog, Select, Typography, TypographySize } from '../../shared';
import ProspectDesignerModalActions from './ProspectDesignerModalActions';

const PROSPECT_DESIGNER_MODAL_WIDTH = 340;

const PROSPECT_TYPE_OPTIONS = Object.values(ProspectType)
  .filter((type) => type !== ProspectType.FULLYRAMPED)
  .map((type) => ({
    label: PROSPECT_TYPE_TO_LABEL[type],
    value: type,
  }));

const ProspectDesignerModal = () => {
  const navigate = useNavigate();

  const [selectedProspectType, setSelectedProspectType] = useState(ProspectType.REPS);
  const [selectedOrgId, setSelectedOrgId] = useState<string>();

  const { data: orgs = [], isLoading } = useGetOrganizationsQuery();

  const orgOptions = useMemo(() => orgs.map(parseOrgToOption), [orgs]);

  // Exits designer mode by navigating to the practice page,
  // since the designer modal only opens in the /prospect page.
  const onCancel = useCallback(() => {
    navigate(AppRoutes.PRACTICE);
  }, [navigate]);

  // Set the first org as the default selected org.
  useEffect(() => {
    if (orgs.length && !selectedOrgId) {
      setSelectedOrgId(orgs[0].id);
    }
  }, [orgs]);

  return (
    <Dialog isOpen title="Prospect designer" width={PROSPECT_DESIGNER_MODAL_WIDTH} onClose={onCancel}>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          <Typography size={TypographySize.H5}>What is the purpose of this prospect?</Typography>
          <Select
            options={PROSPECT_TYPE_OPTIONS}
            selected={PROSPECT_TYPE_OPTIONS.find((option) => option.value === selectedProspectType)}
            onChange={(newValue) => setSelectedProspectType(newValue as ProspectType)}
          />
        </div>
        <div className="flex flex-col gap-4">
          <Typography size={TypographySize.H5}>What organization is this prospect for?</Typography>
          <Select
            options={orgOptions}
            selected={orgOptions.find((option) => option.value === selectedOrgId)}
            onChange={(newValue) => setSelectedOrgId(newValue as string)}
            loading={isLoading}
          />
        </div>
        <Typography color={TextColor.SECONDARY}>
          Note: these cannot be changed. You must create a new prospect to change these properties.
        </Typography>
        <ProspectDesignerModalActions
          isLoading={isLoading}
          selectedOrgId={selectedOrgId}
          selectedProspectType={selectedProspectType}
          onCancel={onCancel}
        />
      </div>
    </Dialog>
  );
};

export default ProspectDesignerModal;
