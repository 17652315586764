import { ButtonColor, Icon, TextButton } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { randomizePriorities, setPriorities } from '../../../../redux/reducers';
import TabSection from '../TabSection';
import ScenarioListItem from './ScenarioListItem';

const Priorities = () => {
  const dispatch = useAppDispatch();
  const priorities = useAppSelector((state) => state.prospect.scenario.priorities) || [];

  // Adds an empty priority field.
  const onAddPriority = () => {
    dispatch(setPriorities([...priorities, { value: '' }]));
  };

  return (
    <TabSection title="Priorities" onRandomize={() => dispatch(randomizePriorities())}>
      {priorities.map((priority, index) => (
        <ScenarioListItem
          key={index}
          index={index}
          items={priorities}
          value={priority.value}
          setItems={(newItems) => dispatch(setPriorities(newItems))}
        />
      ))}
      <TextButton text="Add priority" startIcon={Icon.PLUS} color={ButtonColor.SECONDARY} onClick={onAddPriority} />
    </TabSection>
  );
};

export default Priorities;
