import clsx from 'clsx';
import { ComponentSize } from '../../../types';
import { TOGGLE_SIZE_TO_CLASSNAME } from './Toggle.constants';
import { ToggleProps } from './Toggle.types';

const Toggle = ({ label, onChange, checked, size = ComponentSize.SMALL }: ToggleProps) => {
  return (
    <label className={clsx('label cursor-pointer p-0', !label && 'w-fit')}>
      {label && <span className="label-text">{label}</span>}
      <input
        type="checkbox"
        className={clsx('toggle', TOGGLE_SIZE_TO_CLASSNAME[size])}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
      />
    </label>
  );
};

export default Toggle;
