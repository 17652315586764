import { Dispatch, SetStateAction, useState } from 'react';
import {
  ButtonColor,
  ButtonGroup,
  Dialog,
  DialogType,
  TextButton,
  Typography,
  TypographySize,
} from '../../../components';
import { ComponentSize, ProspectPageMode, ProspectPageTabs } from '../../../types';

interface EditFooterProps {
  setActiveTab: Dispatch<SetStateAction<ProspectPageTabs>>;
  setMode: Dispatch<SetStateAction<ProspectPageMode>>;
}

const EditFooter = ({ setActiveTab, setMode }: EditFooterProps) => {
  const [isRegeneratePromptOpen, setIsRegeneratePromptOpen] = useState(false);

  const exitEditMode = () => {
    setMode(ProspectPageMode.VIEW);
  };

  // TODO: Save changes.
  const handleSaveChanges = () => {
    // If saving is successful, we open the regenerate prompt dialog.
    setIsRegeneratePromptOpen(true);
  };

  // Handles closing the regenerate prompt dialog and exiting edit mode.
  const closeModalAndExitEditMode = () => {
    setIsRegeneratePromptOpen(false);
    exitEditMode();
  };

  // Handles regenerating the prompt.
  // TODO: Regenerate prompt.
  const handleRegenerate = () => {
    // If regenerating the prompt is successful, we exit edit mode and open the prompt tab.
    closeModalAndExitEditMode();
    setActiveTab(ProspectPageTabs.PROMPT);
  };

  return (
    <>
      <ButtonGroup>
        <TextButton text="Save changes" onClick={handleSaveChanges} color={ButtonColor.PRIMARY} />
        <TextButton text="Cancel" onClick={exitEditMode} />
      </ButtonGroup>
      <Dialog
        isOpen={isRegeneratePromptOpen}
        onClose={() => setIsRegeneratePromptOpen(false)}
        title="Regenerate prompt"
        type={DialogType.CONFIRM}
      >
        <div className="flex flex-col gap-4">
          <Typography size={TypographySize.H5}>Changes are saved. Do you want the prompt to be regenerated?</Typography>
          <ButtonGroup size={ComponentSize.MEDIUM}>
            <TextButton text="Regenerate prompt" onClick={handleRegenerate} color={ButtonColor.PRIMARY} />
            <TextButton text="Save without regenerating" onClick={closeModalAndExitEditMode} />
          </ButtonGroup>
        </div>
      </Dialog>
    </>
  );
};

export default EditFooter;
