export const AppRoutes = {
  ANALYTICS: '/analytics',
  CALL_HISTORY: '/call-history',
  FINISH_SETUP: '/finish-setup',
  HOME: '/',
  NOT_FOUND: '*', // Matches any path that hasn't matched above.
  PRACTICE: '/practice',
  PROSPECT: '/practice/prospect',
  GONG_CALLBACK: '/gong-callback',
};
