import clsx from 'clsx';

import { Typography, TypographySize } from '../../../components';
import { TextColor } from '../../../types/color.types';

interface ProspectPageFieldProps {
  children: React.ReactNode;
  label: string;
  fullWidth?: boolean;
}

const ProspectPageField = ({ children, label, fullWidth }: ProspectPageFieldProps) => {
  return (
    <div className={clsx('flex flex-col gap-2', fullWidth ? 'flex-1' : 'flex-0')} key={label}>
      <Typography size={TypographySize.CAPTION} color={TextColor.SECONDARY} className="select-none">
        {label}
      </Typography>
      {children}
    </div>
  );
};

export default ProspectPageField;
