import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
  Text,
} from 'recharts';
import { Orientation } from '../../../types';
import { Typography, TypographySize, TypographyWeight } from '../Typography';
import { AxisType, BarChartKeys, BarChartProps } from './Barchart.types';
import clsx from 'clsx';
import { TYPOGRAPHY_SIZE } from '../Typography/Typography.constants';

// Bar chart constants
// Bar size is the height of the bar
const BAR_SIZE = 16;
// Padding between the bars
const BAR_PADDING = 12;
// Border radius of the bars
const BAR_BORDER_RADUIS = 4;
// Height of the x axis labels
const X_AXIS_LABEL_HEIGHT = 32;
// Width of the y axis labels
const Y_AXIS_LABEL_WIDTH = 100;
// Vertical offset for Y-axis labels
const Y_AXIS_LABEL_OFFSET = 4;

/*
 * Truncates text to fit within a given width.
 * This is necessary because the text can be very long and will overflow the container.
 * Also to have all charts aligned with the same position for the labels
 */

const truncateText = (text: string): string => {
  // Create a temporary canvas for text measurement
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) return text;

  // Gradually remove characters until text + ellipsis fits
  let truncated = text;
  while (truncated.length > 0 && context.measureText(truncated + '...').width > Y_AXIS_LABEL_WIDTH) {
    truncated = truncated.slice(0, -1);
  }

  return truncated.length < text.length ? truncated + '...' : text;
};

const BarChart = ({ data, title, valueFormatter }: BarChartProps) => {
  // Calculate the height of the chart based on the number of bars and the height of each bar
  const chartHeight = data.length * (BAR_SIZE + 2 * BAR_PADDING) + X_AXIS_LABEL_HEIGHT;

  return (
    <div className="flex w-full flex-col gap-4">
      <Typography size={TypographySize.H5} weight={TypographyWeight.SEMI_BOLD}>
        {title}
      </Typography>
      <ResponsiveContainer height={chartHeight}>
        <RechartsBarChart layout={Orientation.VERTICAL} data={data} margin={{ right: 30, left: Y_AXIS_LABEL_WIDTH }}>
          <CartesianGrid horizontal={false} className="stroke-base-200" />
          <XAxis type={AxisType.NUMBER} axisLine={false} tickLine={false} tickFormatter={valueFormatter} />
          <YAxis
            type={AxisType.CATEGORY}
            dataKey={BarChartKeys.LABEL}
            axisLine={{ className: 'stroke-base-200' }}
            tickLine={false}
            tick={(props) => {
              const { x, y, payload } = props;
              return (
                <Text x={x} y={y} dy={Y_AXIS_LABEL_OFFSET} textAnchor="end">
                  {truncateText(payload.value)}
                </Text>
              );
            }}
          />
          <Bar dataKey={BarChartKeys.VALUE} className="fill-primary" radius={BAR_BORDER_RADUIS} barSize={BAR_SIZE}>
            <LabelList
              position="right"
              className={clsx(TYPOGRAPHY_SIZE[TypographySize.CAPTION])}
              formatter={valueFormatter}
            />
          </Bar>
        </RechartsBarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChart;
