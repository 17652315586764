import React from 'react';
import { Icon, TextButton, Typography, TypographySize, TypographyWeight } from '../../../shared';

interface TabHeaderProps {
  title: string | React.ReactNode;
  children?: React.ReactNode;
  handleCreateNew?: () => void;
}

const TabHeader = ({ title, children, handleCreateNew }: TabHeaderProps) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        {typeof title === 'string' && (
          <Typography size={TypographySize.H3} weight={TypographyWeight.SEMI_BOLD}>
            {title}
          </Typography>
        )}
        {typeof title === 'object' && title}
        {handleCreateNew && <TextButton startIcon={Icon.PLUS} text="Create new" onClick={handleCreateNew} />}
      </div>
      {children}
    </div>
  );
};

export default TabHeader;
