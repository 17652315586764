import { useAppDispatch, useAppSelector } from '../../../hooks';
import { closeGongCallsModal } from '../../../redux/reducers';
import { Dialog } from '../../shared';

const GONG_MODAL_WIDTH = 900;

const GongCallsModal = () => {
  const isOpen = useAppSelector((state) => state.modal.isGongCallsModalOpen);
  const dispatch = useAppDispatch();

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => dispatch(closeGongCallsModal())}
      title="Create prospects"
      description="An AI twin will be built based on each selected Gong calls below."
      width={GONG_MODAL_WIDTH}
    >
      <div>GongCallsModal</div>
    </Dialog>
  );
};

export default GongCallsModal;
