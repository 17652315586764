import clsx from 'clsx';
import { ComponentSize } from '../../../types';
import { conditionalObject } from '../../../utils';
import { SKELETON_SIZE } from './Skeleton.constants';
import { SkeletonProps } from './Skeleton.types';

const Skeleton = ({ size = ComponentSize.SMALL }: SkeletonProps) => {
  const isSkeletonSize = typeof size !== 'number';
  return (
    <div
      className={clsx('skeleton w-full', isSkeletonSize && SKELETON_SIZE[size])}
      style={{ ...conditionalObject(!isSkeletonSize, { height: size }) }}
    />
  );
};

export default Skeleton;
