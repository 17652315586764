import { useAppDispatch } from '../../../../../hooks';
import { closeCreateProspectModal, openScorecardForm } from '../../../../../redux/reducers';
import { TextColor } from '../../../../../types';
import { TextButton, Typography, TypographySize, TypographyWeight } from '../../../../shared';
import ItemWrapper from '../ItemWrapper';

const ScorecardTab = () => {
  const dispatch = useAppDispatch();

  return (
    <div className="flex flex-col gap-2 rounded-2xl bg-base-100 p-4">
      <Typography size={TypographySize.H3} weight={TypographyWeight.SEMI_BOLD}>
        Create new scorecard
      </Typography>
      <ItemWrapper fullWidth>
        <div className="flex justify-between">
          <Typography size={TypographySize.H5} color={TextColor.SECONDARY}>
            Build scorecards for evaluating mock calls.
          </Typography>
          <TextButton
            text="Create"
            onClick={() => {
              dispatch(closeCreateProspectModal());
              dispatch(openScorecardForm());
            }}
          />
        </div>
      </ItemWrapper>
    </div>
  );
};

export default ScorecardTab;
