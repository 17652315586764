import * as RadixSlider from '@radix-ui/react-slider';
import clsx from 'clsx';
import { ComponentSize } from '../../../types';
import { SLIDER_SIZE_TO_TRACK_CLASSNAME } from './Slider.constants';

interface SliderTrackProps {
  size: ComponentSize;
}

const SliderTrack = ({ size }: SliderTrackProps) => (
  <RadixSlider.Track
    className={clsx('relative w-full rounded-full bg-gray-200', SLIDER_SIZE_TO_TRACK_CLASSNAME[size])}
  />
);

export default SliderTrack;
