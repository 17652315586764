/**
 * Conditionally inserts key-value pairs into an array.
 */
export const conditionalArray = <T>(condition: boolean, ...elements: T[]): T[] => (condition ? elements : []);

/**
 * Conditionally inserts key-value pairs into an object.
 */
export const conditionalObject = <T>(condition: boolean, elements: T): Partial<T> => {
  return condition ? { ...elements } : {};
};

/** Generates a random element from an array. */
export const getRandomElement = <T>(array: T[]): T => {
  return array[Math.floor(Math.random() * array.length)];
};

/** Generates a random subset of an array. */
export const getRandomElements = <T>(array: T[], length: number) =>
  array.sort(() => Math.random() - 0.5).slice(0, length);
