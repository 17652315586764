import Objections from './Objections';
import Outcome from './Outcome';
import Priorities from './Priorities';
import Questions from './Questions';
import Scene from './Scene';
const ScenarioTab = () => {
  return (
    <div className="flex flex-col gap-4">
      <Priorities />
      <Objections />
      <Questions />
      <Outcome />
      <Scene />
    </div>
  );
};

export default ScenarioTab;
