import clsx from 'clsx';

interface ItemWrapperProps {
  children: React.ReactNode;
  fullWidth?: boolean;
}

const ItemWrapper = ({ children, fullWidth }: ItemWrapperProps) => {
  return <div className={clsx('mt-4 min-h-12', fullWidth ? 'w-full' : 'w-[90%]')}>{children}</div>;
};

export default ItemWrapper;
