import { ButtonColor, findOptionByValue, Icon, Select, TextButton } from '../../../../components';
import { TTS_MODEL_OPTIONS, VOICE_OPTIONS } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { randomizeVoice, setPersonaVoiceId, setTtsModelId } from '../../../../redux/reducers';
import ProspectPageField from '../ProspectPageField';
import TabSection from '../TabSection';

const Voice = () => {
  const dispatch = useAppDispatch();
  const { ttsModelId, personaVoiceId } = useAppSelector((state) => state.prospect.personal);

  const fields = [
    {
      fullWidth: true,
      label: 'Text to speech model*',
      content: (
        <Select
          clearable // Clearable to allow randomizing.
          options={TTS_MODEL_OPTIONS}
          selected={findOptionByValue(TTS_MODEL_OPTIONS, ttsModelId.value)}
          onChange={(value) => dispatch(setTtsModelId({ value, isUserModified: !!value }))}
        />
      ),
    },
    {
      fullWidth: true,
      label: 'Voice*',
      content: (
        <Select
          clearable // Clearable to allow randomizing.
          options={VOICE_OPTIONS}
          selected={findOptionByValue(VOICE_OPTIONS, personaVoiceId.value)}
          onChange={(value) => dispatch(setPersonaVoiceId({ value, isUserModified: !!value }))}
        />
      ),
    },
    {
      label: 'Preview audio',
      content: (
        <TextButton
          disabled
          color={ButtonColor.SECONDARY}
          startIcon={Icon.PLAY}
          text="0:00 / 0:00"
          onClick={() => {}}
        />
      ),
    }, // TODO: Add preview audio.
  ];

  return (
    <TabSection title="Voice" onRandomize={() => dispatch(randomizeVoice())}>
      <div className="flex gap-2">
        {fields.map((field) => (
          <ProspectPageField key={field.label} label={field.label} fullWidth={field.fullWidth}>
            {field.content}
          </ProspectPageField>
        ))}
      </div>
    </TabSection>
  );
};

export default Voice;
