import { setOrganization } from '../redux/reducers';
import { AppOrganization, Roles } from '../types';
import useAppDispatch from './useAppDispatch';
import useAppSelector from './useAppSelector';

const useUpdateOrgSeats = () => {
  const dispatch = useAppDispatch();
  const organization = useAppSelector((state) => state.auth.organization);

  const updateOrgSeats = (oldRole?: Roles, newRole?: Roles) => {
    if (!organization) return;

    const updatedSeats = { ...organization.userSeats };

    // Decrement the old role count
    if (oldRole) updatedSeats[oldRole] = Math.max(0, (updatedSeats[oldRole] || 0) - 1);

    // Increment the new role count
    if (newRole) updatedSeats[newRole] = (updatedSeats[newRole] || 0) + 1;

    const updatedOrg: AppOrganization = {
      ...organization,
      userSeats: updatedSeats as Record<Roles, number>,
    };

    dispatch(setOrganization(updatedOrg));
  };

  return updateOrgSeats;
};

export default useUpdateOrgSeats;

