import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PracticeProspect, Tag } from '../../types';
import { alphanumericCompare } from '../../utils';

const getNextProspect = (prospects: PracticeProspect[], currProspect: PracticeProspect) => {
  const currProspectIndex = prospects.findIndex((prospect) => prospect.personaId === currProspect.personaId);
  if (currProspectIndex === -1) {
    return undefined;
  }

  const isLastProspect = currProspectIndex === prospects.length - 1;
  let nextProspect = prospects.length ? prospects[isLastProspect ? 0 : currProspectIndex + 1] : undefined;

  // If the next prospect is the same as the current prospect, set nextProspect to undefined.
  if (nextProspect && nextProspect.personaId === currProspect.personaId) {
    nextProspect = undefined;
  }

  return nextProspect;
};

export type WebCallData = {
  currProspect: PracticeProspect;
  nextProspect?: PracticeProspect;
};

/**
 * Interface for the practice state.
 */
interface PracticeState {
  /**
   * List of prospects in the order they can be called.
   * After ending a call, the user can call the next prospect in this sequence.
   */
  prospects: PracticeProspect[];
  /**
   * Information about the current practice call.
   * Undefined when no call is in progress.
   */
  currWebCall?: WebCallData;
}

/**
 * Initial state for the practice.
 */
const INITIAL_PRACTICE_STATE: PracticeState = {
  prospects: [],
};

// Create a slice for managing the practice state.
const practiceReducer = createSlice({
  name: 'practice',
  initialState: INITIAL_PRACTICE_STATE,
  reducers: {
    // Set the list of prospects for the practice page.
    setPracticeProspectsList: (state, action: PayloadAction<PracticeProspect[]>) => {
      state.prospects = action.payload;
    },
    // Update the tags for a prospect.
    updateProspectTags: (state, action: PayloadAction<{ personaId: string; tags: Tag[] }>) => {
      const { personaId, tags } = action.payload;
      // Sort the tags by name in alphanumeric order, similar to how the tags are sorted on the server.
      const sortedTags = tags.sort((a, b) => alphanumericCompare(a.name, b.name));
      state.prospects = state.prospects.map((p) => (p.personaId === personaId ? { ...p, tags: sortedTags } : p));
    },
    // Start a web call with the given prospect by setting the currWebCall state
    // which triggers opening the WebCallModal and starting the call.
    startWebCall: (state, action: PayloadAction<{ prospect: PracticeProspect; hideUpNext?: boolean }>) => {
      const { prospect: currProspect, hideUpNext } = action.payload;
      const nextProspect = hideUpNext ? undefined : getNextProspect(state.prospects, currProspect);

      state.currWebCall = {
        currProspect,
        nextProspect,
      };
    },
    // Start the next web call by setting the currWebCall state with the next prospect.
    startNextWebCall: (state) => {
      if (!state.currWebCall || !state.currWebCall.nextProspect) return;

      const currProspect = state.currWebCall.nextProspect;
      const nextProspect = getNextProspect(state.prospects, currProspect);

      state.currWebCall = {
        currProspect,
        nextProspect,
      };
    },
    // Close the web call modal.
    closeWebCall: (state) => {
      state.currWebCall = undefined;
    },
  },
});

export const { setPracticeProspectsList, updateProspectTags, startWebCall, startNextWebCall, closeWebCall } =
  practiceReducer.actions;
export default practiceReducer.reducer;
