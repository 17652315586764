import clsx from 'clsx';
import { Select, TextInput, TextInputType, Typography, TypographySize } from '../../../components';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  randomizeLead,
  setDepartment,
  setJobTitle,
  setNumOfDirectReports,
  setRoleType,
  setTenure,
} from '../../../redux/reducers';
import { RoleType, TextColor } from '../../../types';
import TabSection from './TabSection';

const ROLE_TYPE_SELECT_WIDTH = 200;

const LeadTab = () => {
  const dispatch = useAppDispatch();
  const { jobTitle, tenure, roleType, department, numOfDirectReports } = useAppSelector((state) => state.prospect.lead);

  const roleTypeOptions = Object.values(RoleType).map((value) => ({ label: value, value }));
  const selectedRoleTypeOption = roleTypeOptions.find((option) => option.value === roleType.value);

  const fields = [
    [
      {
        label: 'Job title*',
        content: (
          <TextInput
            value={jobTitle.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const newJobTitle = e.target.value;
              dispatch(setJobTitle({ value: newJobTitle, isUserModified: !!newJobTitle }));
            }}
          />
        ),
      },
      {
        label: 'Tenure',
        content: (
          <TextInput
            type={TextInputType.NUMBER}
            value={tenure?.value?.toString()}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const newTenure = e.target.value;
              dispatch(setTenure({ value: parseInt(newTenure), isUserModified: !!newTenure }));
            }}
          />
        ),
      },
      {
        label: 'Role type*',
        content: (
          <Select
            clearable
            options={roleTypeOptions}
            selected={selectedRoleTypeOption}
            width={ROLE_TYPE_SELECT_WIDTH}
            onChange={(value?: string) => {
              const newRoleType = value as RoleType;
              dispatch(setRoleType({ value: newRoleType, isUserModified: !!newRoleType }));
            }}
          />
        ),
      },
    ],
    [
      {
        label: 'Department',
        content: (
          <TextInput
            value={department.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const newDepartment = e.target.value;
              dispatch(setDepartment({ value: newDepartment, isUserModified: !!newDepartment }));
            }}
          />
        ),
      },
      {
        label: '# of direct reports',
        content: (
          <TextInput
            type={TextInputType.NUMBER}
            value={numOfDirectReports?.value?.toString()}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const newNumOfDirectReports = e.target.value;
              dispatch(
                setNumOfDirectReports({
                  value: parseInt(newNumOfDirectReports),
                  isUserModified: !!newNumOfDirectReports,
                })
              );
            }}
          />
        ),
      },
    ],
  ];

  return (
    <TabSection title="Lead" onRandomize={() => dispatch(randomizeLead())}>
      {fields.map((rowFields, index) => (
        <div className="flex w-full gap-4" key={index}>
          {rowFields.map(({ label, content }, index) => (
            <div className={clsx('flex flex-col gap-2', index === 0 && 'w-full')} key={label}>
              <Typography size={TypographySize.CAPTION} color={TextColor.SECONDARY} className="select-none">
                {label}
              </Typography>
              {content}
            </div>
          ))}
        </div>
      ))}
    </TabSection>
  );
};

export default LeadTab;
