import * as RadixSlider from '@radix-ui/react-slider';
import clsx from 'clsx';

import { ComponentSize } from '../../../types';
import { SLIDER_SIZE_TO_PX, THUMB_BORDER_WIDTH } from './Slider.constants';

interface SliderThumbProps {
  hideProgress: boolean;
  min: number;
  size: ComponentSize;
  value: number;
}

const SliderThumb = ({ hideProgress, min, size, value }: SliderThumbProps) => {
  // If there is no progress, we want to round the thumb completely.
  const borderRadiusClassName = hideProgress || value === min ? 'rounded-full' : 'rounded-r-full';

  // We make the inner thumb slightly smaller than the outer size to account for the border.
  const thumbOuterSize = SLIDER_SIZE_TO_PX[size];
  const thumbInnerSize = thumbOuterSize - THUMB_BORDER_WIDTH;

  return (
    <RadixSlider.Thumb
      className={clsx('flex cursor-grab items-center justify-center bg-neutral outline-none', borderRadiusClassName)}
      style={{ height: thumbOuterSize, width: thumbOuterSize }}
    >
      <div className="rounded-full bg-base-100" style={{ height: thumbInnerSize, width: thumbInnerSize }} />
    </RadixSlider.Thumb>
  );
};

export default SliderThumb;
