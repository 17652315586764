import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Call } from '../../types';

type CallDetails = {
  callSid: string;
  startTime?: number;
  autoPlay?: boolean;
  // Whether the Call Details drawer is open.
  isCallDetailsDrawerOpen?: boolean;
};

/**
 * Interface for the call page state.
 */
interface CallHistoryState {
  /**
   * List of calls displayed in the call history table.
   * The list is filtered and sorted based on the user's selected filters.
   */
  calls: Call[];
  /**
   * Represents the current call details if the media player modal is open,
   * otherwise undefined.
   */
  currentCallDetails?: CallDetails;
}

/**
 * Initial state for the call page.
 */
const INITIAL_CALL_HISTORY_STATE: CallHistoryState = {
  calls: [],
};

// Create a slice for managing the call page state.
const callHistoryReducer = createSlice({
  name: 'callHistory',
  initialState: INITIAL_CALL_HISTORY_STATE,
  reducers: {
    // Action to set the list of calls.
    setCalls: (state, action: PayloadAction<Call[]>) => {
      state.calls = action.payload;
    },
    // Action to update the flagged state of a call.
    updateCallFlagged: (
      state,
      action: PayloadAction<{ callSid: string; flaggedUserIds?: string[]; isFlaggedToUser?: boolean }>
    ) => {
      const { callSid, flaggedUserIds, isFlaggedToUser } = action.payload;
      state.calls = state.calls.map((c) => (c.callSid === callSid ? { ...c, flaggedUserIds, isFlaggedToUser } : c));
    },
    // Action to set the call details for the media player.
    // Setting currentCallDetails opens the media player, setting it to undefined closes it.
    setCurrentCallDetails: (state, action: PayloadAction<CallDetails | undefined>) => {
      state.currentCallDetails = action.payload;
    },
    // Action to toggle the drawer state
    // Needed to preserve existing call store details while only updating the drawer open state.
    setIsCallDetailsDrawerOpen: (state, action: PayloadAction<boolean>) => {
      if (state.currentCallDetails) {
        state.currentCallDetails.isCallDetailsDrawerOpen = action.payload;
      }
    },
  },
});

export const { setCalls, updateCallFlagged, setCurrentCallDetails, setIsCallDetailsDrawerOpen } =
  callHistoryReducer.actions;
export default callHistoryReducer.reducer;
