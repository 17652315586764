import * as RadixSlider from '@radix-ui/react-slider';
import clsx from 'clsx';
import { ComponentSize } from '../../../types';
import { Typography } from '../Typography';
import { SLIDER_DEFAULT_MAX, SLIDER_DEFAULT_MIN, SLIDER_DEFAULT_STEP, SLIDER_SIZE_TO_PX } from './Slider.constants';
import { SliderProps } from './Slider.types';
import SliderProgress from './SliderProgress';
import SliderThumb from './SliderThumb';
import SliderTrack from './SliderTrack';

const Slider = ({
  className,
  hideProgress = false,
  hideStep = false,
  max = SLIDER_DEFAULT_MAX,
  min = SLIDER_DEFAULT_MIN,
  size = ComponentSize.SMALL,
  step = SLIDER_DEFAULT_STEP,
  value: valueProp,
  onChange,
}: SliderProps) => {
  // Ensures that the value is always within the min and max values.
  const getValue = () => {
    // If it is undefined, return the min value.
    if (valueProp === undefined) return min;
    // If it is less than the min, return the min value.
    if (valueProp < min) return min;
    // If it is greater than the max, return the max value.
    if (valueProp > max) return max;
    // Otherwise, return the value.
    return valueProp;
  };

  const value = getValue();

  return (
    <div className="flex w-full items-center gap-2">
      <RadixSlider.Root
        className={clsx('relative flex w-full items-center', className)}
        min={min}
        max={max}
        step={step}
        value={[value]}
        onValueChange={(value) => onChange(value[0])}
        style={{ height: SLIDER_SIZE_TO_PX[size] }}
      >
        <SliderTrack size={size} />
        {!hideProgress && <SliderProgress max={max} min={min} value={value} />}
        <SliderThumb hideProgress={hideProgress} min={min} size={size} value={value} />
      </RadixSlider.Root>
      {!hideStep && <Typography>{`${value}/${max}`}</Typography>}
    </div>
  );
};

export default Slider;
