import clsx from 'clsx';
import { Select, TextInput, Typography, TypographySize } from '../../../components';
import { ANNUAL_REVENUE_RANGES, COMPANY_SIZE_RANGES } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  randomizeAccount,
  setAccountName,
  setAnnualRevenue,
  setFundingRaised,
  setHQLocation,
  setIndustry,
  setIsMultiSite,
  setNumOfEmployees,
  setRemoteType,
} from '../../../redux/reducers';
import { FundingRaised, MultiSiteType, RemoteType, TextColor } from '../../../types';
import TabSection from './TabSection';

const DOLLAR = '$';
const MILLION_UNIT = { value: 1000000, suffix: 'M' };
const REMOTE_AND_MULTI_SITE_WIDTH = 200;

const getRangeOption = (range: [number, number], prefix = '', unit = { value: 1, suffix: '' }) => {
  // Decompose the range into from and to.
  const [from, to] = range;

  // Determine if the suffix should be shown.
  const shouldShowFromSuffix = from / unit.value > 0;
  const shouldShowToSuffix = to / unit.value > 0;

  // Get the suffix to show.
  const fromSuffix = shouldShowFromSuffix ? unit.suffix : '';
  const toSuffix = shouldShowToSuffix ? unit.suffix : '';

  let label = '';
  if (to === Infinity) {
    // If to is Infinity, indicate it with a '+' suffix.
    label = `${prefix}${from / unit.value}${fromSuffix}+`;
  } else {
    // Otherwise, show the range normally.
    label = `${prefix}${from / unit.value}${fromSuffix} - ${prefix}${to / unit.value}${toSuffix}`;
  }

  return { label, value: range.join(',') };
};

const getSelectedRangeOption = (options: { label: string; value: string }[], range?: [number, number]) => {
  // If no range is provided, return undefined.
  if (!range) return undefined;
  // Find the option that matches the range.
  return options.find((option) => {
    const [from, to] = option.value.split(',').map(Number);
    return from === range[0] && to === range[1];
  });
};

const AccountTab = () => {
  const dispatch = useAppDispatch();
  const { accountName, industry, hqLocation, remoteType, isMultiSite, numOfEmployees, annualRevenue, fundingRaised } =
    useAppSelector((state) => state.prospect.account);

  const remoteOptions = Object.values(RemoteType).map((option) => ({ label: option, value: option }));
  const selectedRemoteOption = remoteOptions.find((option) => option.value === remoteType.value);

  const multiSiteOptions = Object.values(MultiSiteType).map((option) => ({ label: option, value: option }));
  const selectedMultiSiteOption = multiSiteOptions.find((option) => {
    if (isMultiSite.value === undefined) return undefined;
    return option.value === (isMultiSite.value ? MultiSiteType.MULTI_SITE : MultiSiteType.SINGLE_SITE);
  });

  const numOfEmployeesOptions = COMPANY_SIZE_RANGES.map((range) => getRangeOption(range));
  const selectedNumOfEmployeesOption = getSelectedRangeOption(numOfEmployeesOptions, numOfEmployees.value);

  const annualRevenueOptions = ANNUAL_REVENUE_RANGES.map((range) => getRangeOption(range, DOLLAR, MILLION_UNIT));
  const selectedAnnualRevenueOption = getSelectedRangeOption(annualRevenueOptions, annualRevenue.value);

  const fundingRaisedOptions = Object.values(FundingRaised).map((option) => ({ label: option, value: option }));
  const selectedFundingRaisedOption = fundingRaisedOptions.find((option) => option.value === fundingRaised.value);

  const fields = [
    [
      {
        label: 'Company name*',
        content: (
          <TextInput
            value={accountName.value}
            onChange={(e) => {
              const newAccountName = e.target.value;
              dispatch(setAccountName({ value: newAccountName, isUserModified: !!newAccountName }));
            }}
          />
        ),
      },
      {
        label: 'Industry*',
        content: (
          <TextInput
            value={industry.value}
            onChange={(e) => {
              const newIndustry = e.target.value;
              dispatch(setIndustry({ value: newIndustry, isUserModified: !!newIndustry }));
            }}
          />
        ),
      },
    ],
    [
      {
        label: 'HQ location',
        content: (
          <TextInput
            value={hqLocation.value}
            onChange={(e) => {
              const newHQLocation = e.target.value;
              dispatch(setHQLocation({ value: newHQLocation, isUserModified: !!newHQLocation }));
            }}
          />
        ),
      },
      {
        width: 'w-fit',
        label: 'Remote?',
        content: (
          <Select
            clearable
            options={remoteOptions}
            selected={selectedRemoteOption}
            width={REMOTE_AND_MULTI_SITE_WIDTH}
            onChange={(value?: string) => {
              const newRemote = value as RemoteType;
              dispatch(setRemoteType({ value: newRemote, isUserModified: !!newRemote }));
            }}
          />
        ),
      },
      {
        width: 'w-fit',
        label: 'Multi-site?',
        content: (
          <Select
            clearable
            options={multiSiteOptions}
            selected={selectedMultiSiteOption}
            width={REMOTE_AND_MULTI_SITE_WIDTH}
            onChange={(value?: string) => {
              const newMultiSite = value as MultiSiteType;
              const newValue = value === undefined ? undefined : newMultiSite === MultiSiteType.MULTI_SITE;
              dispatch(
                setIsMultiSite({
                  value: newValue,
                  isUserModified: newValue !== undefined,
                })
              );
            }}
          />
        ),
      },
    ],
    [
      {
        label: '# of employees',
        content: (
          <Select
            clearable
            options={numOfEmployeesOptions}
            selected={selectedNumOfEmployeesOption}
            onChange={(value?: string) => {
              const newNumOfEmployees = value?.split(',').map(Number) ?? [];
              if (newNumOfEmployees[0] === undefined || newNumOfEmployees[1] === undefined) {
                dispatch(setNumOfEmployees({ value: undefined, isUserModified: false }));
              } else {
                dispatch(setNumOfEmployees({ value: newNumOfEmployees as [number, number], isUserModified: true }));
              }
            }}
          />
        ),
      },
      {
        label: 'Annual revenue',
        content: (
          <Select
            clearable
            options={annualRevenueOptions}
            selected={selectedAnnualRevenueOption}
            onChange={(value?: string) => {
              const newAnnualRevenue = value?.split(',').map(Number) ?? [];
              if (newAnnualRevenue[0] === undefined || newAnnualRevenue[1] === undefined) {
                dispatch(setAnnualRevenue({ value: undefined, isUserModified: false }));
              } else {
                dispatch(setAnnualRevenue({ value: newAnnualRevenue as [number, number], isUserModified: true }));
              }
            }}
          />
        ),
      },
      {
        label: 'Funding raised',
        content: (
          <Select
            clearable
            options={fundingRaisedOptions}
            selected={selectedFundingRaisedOption}
            onChange={(value?: string) => {
              const newFundingRaised = value as FundingRaised;
              dispatch(setFundingRaised({ value: newFundingRaised, isUserModified: !!newFundingRaised }));
            }}
          />
        ),
      },
    ],
  ];

  return (
    <TabSection title="Account" onRandomize={() => dispatch(randomizeAccount())}>
      {fields.map((rowFields, index) => (
        <div className="flex gap-4" key={index}>
          {rowFields.map(({ label, content, width }) => (
            <div className={clsx('flex flex-col gap-2', width ?? 'w-full')} key={label}>
              <Typography size={TypographySize.CAPTION} color={TextColor.SECONDARY} className="select-none">
                {label}
              </Typography>
              {content}
            </div>
          ))}
        </div>
      ))}
    </TabSection>
  );
};

export default AccountTab;
