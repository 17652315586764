import { Pagination } from './api.types';
import { Tag } from './tags.types';

export enum ProspectType {
  REPS = 'REPS',
  CANDIDATES = 'CANDIDATES',
  GTM = 'GTM',
  FULLYRAMPED = 'FULLYRAMPED',
}

// -- ENUMS --
export enum ProspectSortingFilters {
  ASSOCIATED_PHONE_NUMBER = 'associatedPhoneNumber',
  FIRST_NAME = 'firstName',
  CREATED_AT = 'createdAt',
}

// -- TYPES --
export type BasePracticeProspect = {
  accountName: string;
  firstName: string;
  lastName: string;
  personaId: string;
  jobTitle: string;
  associatedPhoneNumber: string;
  type: ProspectType;
};

type PracticeProspectWithoutTags = BasePracticeProspect & {
  createdAt: Date;
  createdBy: string;
  lastModified: Date;
  lastModifiedBy: string;
  notes?: string;
};

/** Represents a Practice Prospect as it is used across the app with tags as a flat array of Tag objects. */
export type PracticeProspect = PracticeProspectWithoutTags & { tags: Tag[] };

/** Represents a Practice Prospect as it comes from the API with tags as an array of objects with a tag property. */
export type DBPracticeProspect = PracticeProspectWithoutTags & { tags: { tag: Tag }[] };

export type PracticeProspectsResponse = {
  data: {
    prospects: DBPracticeProspect[];
    pagination: Pagination;
  };
};

export type HotlinePracticeProspectResponse = {
  data: {
    prospect: DBPracticeProspect;
  };
};

export type PracticeProspectsSelectResponse = {
  data: {
    prospects: BasePracticeProspect[];
  };
};

/** Represents data related to a prospect. */
export type ProspectColumnData = {
  // The company where the prospect works.
  company: string;
  // The job title of the prospect.
  jobTitle: string;
  // The full name of the prospect.
  name: string;
  // The ID of the prospect.
  id?: string;
  // The tags associated with the prospect.
  tags?: Tag[];
};

export type ProspectColumn = {
  phoneNumber: string;
  prospect: ProspectColumnData;
  createdAt: Date;
  notes?: string;
};

/** Represents data related to a selected prospect. */
export type GetPracticeProspectsForSelectPayload = {
  type?: string;
  includeJobTitle?: boolean;
  includeAssociatedPhoneNumber?: boolean;
};

// -- PROSPECT PAGE --

/** Represents the mode of the prospect page. */
export enum ProspectPageMode {
  CREATE = 'Create',
  EDIT = 'Edit',
  VIEW = 'View',
}

/** Represents the tabs of the prospect page. */
export enum ProspectPageTabs {
  PERSONAL = 'personal',
  LEAD = 'lead',
  ACCOUNT = 'account',
  SCENARIO = 'scenario',
  CONFIGURATION = 'configuration',
  PROMPT = 'prompt',
}

// -- PERSONAL --

/** Represents the pronoun options for a prospect. */
export enum Pronouns {
  HE_HIM_HIS = 'He / him / his',
  SHE_HER_HERS = 'She / her / hers',
  THEY_THEM_THEIRS = 'They / them / theirs',
}

// -- LEAD --

/** Represents the role type options for a prospect. */
export enum RoleType {
  CHAMPION = 'Champion',
  GATEKEEPER = 'Gatekeeper',
  DECISION_MAKER = 'Decision maker',
}

// -- ACCOUNT --

/** Represents the work options for a prospect. */
export enum RemoteType {
  REMOTE = 'Remote',
  HYBRID = 'Hybrid',
  IN_PERSON = 'In-person',
}

/** Represents the multi-site options for a prospect. */
export enum MultiSiteType {
  MULTI_SITE = 'Multi-site',
  SINGLE_SITE = 'Single-site',
}

/** Represents the funding raised options for a prospect. */
export enum FundingRaised {
  SMB = 'SMB',
  SEED = 'Seed',
  SERIES_A = 'Series A',
  SERIES_B = 'Series B',
  SERIES_C = 'Series C',
  SERIES_D_PLUS = 'Series D+',
  PRIVATE_EQUITY = 'Private equity',
  PUBLIC_SMALL_CAP = 'Public small cap',
  PUBLIC_MID_CAP = 'Public mid cap',
  PUBLIC_LARGE_CAP = 'Public large cap',
}

// -- SCENARIO --

/** Represents the successful result options for a call with a prospect. */
export enum ProspectSuccessResult {
  BOOKED_A_MEETING = 'Booked a meeting',
  QUALIFY_PROSPECT = 'Qualify prospect',
  GET_REFERRAL = 'Get referral',
}
