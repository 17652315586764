import { useEffect, useRef, useState } from 'react';
import { Badge, BadgeType, MoreTagsLabel } from '../../../components';
import { AppRoutes } from '../../../constants';
import { useUpdateFilterParams, useWindowResize } from '../../../hooks';
import { CallHistoryFilterKeys, Tag } from '../../../types';

const TAGS_GAP = 8;
// TODO: Dynamically calculate more tags label width
const MORE_TAGS_LABEL_WIDTH = 44;

interface TagsRowProps {
  tags: Tag[];
}

const TagsRow = ({ tags }: TagsRowProps) => {
  const [hiddenTags, setHiddenTags] = useState<Tag[]>([]);
  const [lastVisibleIndex, setLastVisibleIndex] = useState<number>(tags.length - 1);

  const containerRef = useRef<HTMLDivElement>(null);
  const tagsRef = useRef<(HTMLDivElement | null)[]>([]);

  const updateFilterParams = useUpdateFilterParams();
  const windowWidth = useWindowResize();

  const handleTagClick = (tagId: string) => {
    updateFilterParams(CallHistoryFilterKeys.TAGS, tagId, AppRoutes.CALL_HISTORY);
  };

  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current && tagsRef.current.length > 0) {
        const containerRect = containerRef.current.getBoundingClientRect();
        const newHiddenTags: Tag[] = [];

        let availableWidth = containerRect.width - (MORE_TAGS_LABEL_WIDTH + TAGS_GAP);
        let newLastVisibleIndex = tags.length - 1;

        for (let i = 0; i < tagsRef.current.length; i++) {
          const tagRef = tagsRef.current[i];

          if (!tagRef) continue;

          const tagRect = tagRef.getBoundingClientRect();

          // Check if the tag fits
          if (tagRect.width > availableWidth) {
            // Save last visible index
            newLastVisibleIndex = i - 1;
            // If it doesn't fit, add this tag and all remaining tags to hidden tags
            newHiddenTags.push(...tags.slice(i));
            break;
          }

          // Reduce available width for the next tag
          availableWidth -= tagRect.width + TAGS_GAP;
        }

        setLastVisibleIndex(newLastVisibleIndex);
        setHiddenTags(newHiddenTags);
      }
    };

    checkOverflow();
  }, [tags, windowWidth, containerRef.current]);

  return (
    <div ref={containerRef} className="flex w-full items-center overflow-hidden" style={{ gap: TAGS_GAP }}>
      {tags.map((tag, index) => (
        <div className="flex items-center gap-2" key={tag.id}>
          <div
            ref={(el) => (tagsRef.current[index] = el)}
            // Hide tag by changing opacity to maintain its position in the DOM
            // This allows for accurate overflow detection and smooth transitions
            className={hiddenTags.find((hiddenTag) => hiddenTag.id === tag.id) ? 'opacity-0' : ''}
          >
            <Badge
              label={tag.name}
              dotColor={tag.color}
              type={BadgeType.GHOST}
              onClick={handleTagClick ? () => handleTagClick(tag.id) : undefined}
              showDot
            />
          </div>
          {/* Only show the "More tags" label if there are hidden tags and it's the last visible tag */}
          {index === lastVisibleIndex && hiddenTags.length > 0 && <MoreTagsLabel hiddenTags={hiddenTags} />}
        </div>
      ))}
    </div>
  );
};

export default TagsRow;
