import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertType, Icon } from '../../components';
import { AppRoutes } from '../../constants';
import { useAppDispatch, useAppSelector, useToast } from '../../hooks';
import { startWebCall } from '../../redux/reducers/practiceReducer';
import { Permissions, PracticeFilterKeys, PracticeProspect } from '../../types';
import { conditionalArray } from '../../utils';

const useProspectActions = (onClose: () => void, clickedProspect?: PracticeProspect) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const permissions = user?.permissions || [];
  const isSuperAdmin = permissions.includes(Permissions.ADMIN_ACCESS);

  const { showToast } = useToast();

  // Handles copying the prospect's phone number to the clipboard.
  const onCopy = useCallback(
    (prospect: PracticeProspect) => {
      const phoneNumber = prospect.associatedPhoneNumber;
      navigator.clipboard.writeText(phoneNumber);
      showToast({ message: 'Phone number copied to clipboard', type: AlertType.SUCCESS });
    },
    [showToast]
  );

  // Handles initiating a phone call to the provided prospect.
  const onDial = useCallback(
    (prospect: PracticeProspect) => {
      dispatch(startWebCall({ prospect }));
    },
    [dispatch]
  );

  // Handles copying the prospect's link to the clipboard.
  const onShare = useCallback(
    (prospect: PracticeProspect) => {
      // Generate the URL based on the current window location.
      const url = new URL(window.location.href);
      // Clear all existing search parameters.
      url.search = '';
      // Set the new query parameter.
      url.searchParams.set(PracticeFilterKeys.PROSPECT, prospect.personaId);

      // Copy the URL to the clipboard.
      navigator.clipboard.writeText(url.toString());
      showToast({ message: 'Link to prospect copied to clipboard', type: AlertType.SUCCESS });
    },
    [showToast]
  );

  // Handles navigating to the prospect page.
  const onViewProspect = useCallback(
    (prospect: PracticeProspect) => {
      const personaId = prospect.personaId;
      navigate(`${AppRoutes.PROSPECT}/${personaId}`);
    },
    [navigate]
  );

  // Handles performing the provided prospect action
  // by calling the action with the clicked prospect if it exists and then closing the modal.
  const handleProspectAction = useCallback(
    (action: (prospect: PracticeProspect) => void) => {
      if (!clickedProspect) return;
      action(clickedProspect);
      onClose();
    },
    [clickedProspect, onClose]
  );

  return [
    {
      label: 'Call',
      icon: Icon.PHONE,
      onClick: () => handleProspectAction(onDial),
    },
    ...conditionalArray(isSuperAdmin, {
      label: 'View prospect',
      icon: Icon.USER_SEARCH,
      onClick: () => handleProspectAction(onViewProspect),
    }),
    {
      label: 'Copy phone number',
      icon: Icon.COPY,
      onClick: () => handleProspectAction(onCopy),
    },
    {
      label: 'Share',
      icon: Icon.SHARE,
      successMsg: 'Link to practice prospect copied to clipboard',
      onClick: () => handleProspectAction(onShare),
    },
  ];
};

export default useProspectActions;
