import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setOAuthConnectionAttempt, SettingsModalTabs } from '../../redux/reducers';
import { useAppDispatch, useToast } from '../../hooks';
import { AlertType, Spinner } from '../../components';
import { ComponentSize } from '../../types';
import { AppRoutes, SETTINGS_ACTIVE_TAB_PARAM, SETTINGS_OPEN_PARAM } from '../../constants';

const GongCallbackPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { showToast } = useToast();

  useEffect(() => {
    const success = searchParams.get('success') === 'true';

    // Set the connection attempt result in Redux
    dispatch(setOAuthConnectionAttempt(success));

    // Create URLSearchParams for navigation
    const queryParams = new URLSearchParams();
    queryParams.set(SETTINGS_OPEN_PARAM, 'true');
    queryParams.set(SETTINGS_ACTIVE_TAB_PARAM, SettingsModalTabs.INTEGRATIONS);

    // Navigate to the practice page with query parameters
    navigate({
      pathname: AppRoutes.PRACTICE,
      search: queryParams.toString(),
    });

    // Handle the response
    if (success) {
      showToast({ message: 'Successfully connected to Gong', type: AlertType.SUCCESS });
    } else {
      showToast({
        title: 'Failed to connect to Gong',
        message: searchParams.get('error_description') ?? '',
        type: AlertType.ERROR,
      });
    }
  }, [searchParams, navigate, dispatch, showToast]);

  return (
    <div className="flex h-screen items-center justify-center">
      <Spinner size={ComponentSize.MEDIUM} />
    </div>
  );
};

export default GongCallbackPage;
