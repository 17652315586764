import { useAppSelector } from '../../../../../../hooks';
import { Divider } from '../../../../../shared';
import SeatsCard from './SeatCard';

const SeatCards = () => {
  const { organization } = useAppSelector((state) => state.auth);

  const { adminSeats = 0, repSeats = 0, managerSeats = 0, userSeats } = organization || {};

  // Total allowed seats
  const totalAdminAndManagerSeats = adminSeats + managerSeats;
  const totalRepSeats = repSeats;

  // Used seats
  const usedAdminAndManagerSeats = (userSeats?.ADMIN || 0) + (userSeats?.MANAGER || 0);
  const usedRepSeats = userSeats?.SALES_REP || 0;

  // Available seats
  // If used seats are more than total seats, it means total seats aren't correctly updated in retool, set available seats to 0
  const availableAdminAndManagerSeats = Math.max(0, totalAdminAndManagerSeats - usedAdminAndManagerSeats);
  const availableRepSeats = Math.max(0, totalRepSeats - usedRepSeats);

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex w-full gap-8">
        <SeatsCard
          title="Admin & managers"
          usedSeats={usedAdminAndManagerSeats}
          availableSeats={availableAdminAndManagerSeats}
        />
        <SeatsCard title="Representatives" usedSeats={usedRepSeats} availableSeats={availableRepSeats} />
      </div>
      <div className="py-2">
        <Divider />
      </div>
    </div>
  );
};

export default SeatCards;
