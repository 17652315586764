import { useState } from 'react';
import { MultiSelect, MultiSelectProps, Typography } from '../../shared';

const FILTERS_SEARCH_THRESHOLD = 6;

type SidebarSelectFilterProps = MultiSelectProps & {
  title: string;
  clearable?: boolean;
};

const SidebarSelectFilter = ({ title, clearable = true, ...selectProps }: SidebarSelectFilterProps) => {
  const [searchValue, setSearchValue] = useState('');

  // Enable search if the number of options is greater than the threshold.
  const enableSearch = selectProps.options.length > FILTERS_SEARCH_THRESHOLD;

  return (
    <div className="flex flex-col gap-2">
      <Typography>{title}</Typography>
      <MultiSelect
        {...selectProps}
        clearable={clearable}
        searchProps={enableSearch ? { searchValue, setSearchValue, placeholder: 'Type to filter' } : undefined}
      />
    </div>
  );
};

export default SidebarSelectFilter;
