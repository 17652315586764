import { useAppSelector } from '../../../../../hooks';
import { TextColor } from '../../../../../types';
import { Icons, Icon, Typography, TextButton } from '../../../../shared';

interface ConnectButtonProps {
  onConnect: () => Promise<void>;
  isLoading: boolean;
  isFailedConnection: boolean;
}

const ConnectButton = ({ onConnect, isLoading, isFailedConnection }: ConnectButtonProps) => {
  const { gongAuth } = useAppSelector((state) => state.auth.organization) || {};

  const showConnectionFailed = isFailedConnection && !gongAuth;
  const showConnectionSuccess = !!gongAuth && !isFailedConnection;

  return (
    <div className="flex items-center gap-4">
      <TextButton text="Connect" onClick={onConnect} loading={isLoading} disabled={!!gongAuth} />
      <div className="flex items-center gap-2">
        {/* Gong is already connected */}
        {showConnectionSuccess && (
          <>
            <Icons icon={Icon.CHECK} color={TextColor.SUCCESS} />
            <Typography color={TextColor.SUCCESS}>Connected</Typography>
          </>
        )}
        {/* Connection failed */}
        {showConnectionFailed && (
          <>
            <Icons icon={Icon.CLOSE} color={TextColor.DESTRUCTIVE} />
            <Typography color={TextColor.DESTRUCTIVE}>Connection failed</Typography>
          </>
        )}
      </div>
    </div>
  );
};

export default ConnectButton;
