import { Icon, Icons, Typography, TypographySize, TypographyWeight } from '../../../components';
import { TextColor } from '../../../types';

interface TabSectionProps {
  title: string;
  children: React.ReactNode;
  description?: string;
  onRandomize?: () => void;
}

const TabSection = ({ title, children, description, onRandomize }: TabSectionProps) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-1">
        <div className="flex items-center gap-1">
          <Typography size={TypographySize.H5} weight={TypographyWeight.SEMI_BOLD} className="select-none">
            {title}
          </Typography>
          {onRandomize && <Icons icon={Icon.WAND} color={TextColor.AI} onClick={onRandomize} tooltip="Fill for me" />}
        </div>
        <Typography size={TypographySize.CAPTION} color={TextColor.SECONDARY} className="select-none">
          {description}
        </Typography>
      </div>
      {children}
    </div>
  );
};

export default TabSection;
