import clsx from 'clsx';
import { Dispatch, SetStateAction, useState } from 'react';
import { Badge, BadgeType, Icon, Icons } from '../../../components';
import { ComponentSize, TextColor } from '../../../types';

interface ManageTagsTriggerProps {
  active: boolean;
  showBadge: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const ManageTagsTrigger = ({ active, showBadge, setIsOpen }: ManageTagsTriggerProps) => {
  const [hover, setHover] = useState(false);

  const bgColor = active || hover ? 'bg-neutral' : 'bg-gray-100';
  const iconColor = active || hover ? TextColor.WHITE : TextColor.PRIMARY;

  if (showBadge) {
    return (
      <Badge
        active={active}
        label="Add tags"
        type={BadgeType.OUTLINE}
        icon={Icon.PLUS}
        onClick={() => setIsOpen(true)}
      />
    );
  }

  return (
    <div
      className={clsx('flex h-fit w-fit cursor-pointer items-center justify-center rounded-full p-[1px]', bgColor)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={(e) => {
        e.stopPropagation();
        setIsOpen(true);
      }}
    >
      <Icons icon={Icon.PLUS} color={iconColor} size={ComponentSize.X_SMALL} />
    </div>
  );
};

export default ManageTagsTrigger;
