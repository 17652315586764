import clsx from 'clsx';
import { ComponentSize } from '../../../types';
import { SPINNER_SIZE } from './Spinner.constants';
import { SpinnerProps } from './Spinner.types';

const Spinner = ({ className, size = ComponentSize.LARGE }: SpinnerProps) => {
  return <div className={clsx('loading loading-spinner', SPINNER_SIZE[size], className)} />;
};

export default Spinner;
