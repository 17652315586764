import { useState } from 'react';
import { LD_FeatureFlags, Roles, TextColor } from '../../../../../types';
import { Accordion, Icon, Icons, Typography, TypographySize } from '../../../../shared';
import ItemWrapper from '../ItemWrapper';
import AITwinSubmitFile from './AITwinSubmitFile';
import AITwinSubmitLink from './AITwinSubmitLink';
import AITwinSubmitSuccess from './AITwinSubmitSuccess';
import AITwinGong from './AITwinGong';
import { useAppSelector, useFeatureFlag } from '../../../../../hooks';
import { conditionalArray } from '../../../../../utils';

enum AITwinItem {
  FILE = 'FILE',
  LINK = 'LINK',
  GONG = 'GONG',
}

const AITwinTab = () => {
  const [fileInput, setFileInput] = useState<File | undefined>(undefined);
  const [linkInput, setLinkInput] = useState('');

  const [fileInputError, setFileInputError] = useState('');
  const [linkInputError, setLinkInputError] = useState('');

  const [isFileSubmitted, setIsFileSubmitted] = useState(false);
  const [isLinkSubmitted, setIsLinkSubmitted] = useState(false);

  const gongFF = useFeatureFlag(LD_FeatureFlags.RELEASE_GONG);
  const { user, organization } = useAppSelector((state) => state.auth);
  const isSalesRep = user?.role === Roles.SALES_REP;
  const isUploadModalEnabled = organization?.isUploadModalEnabled;

  const items = [
    {
      id: AITwinItem.FILE,
      title: 'Upload call',
      disabled: !isUploadModalEnabled,
      content: isFileSubmitted ? (
        <AITwinSubmitSuccess handleSubmitAnother={() => setIsFileSubmitted(false)} />
      ) : (
        <AITwinSubmitFile
          error={fileInputError}
          setError={setFileInputError}
          setFileInput={setFileInput}
          fileInput={fileInput}
          setIsFileSubmitted={setIsFileSubmitted}
        />
      ),
    },
    {
      id: AITwinItem.LINK,
      title: 'Share call link',
      disabled: !isUploadModalEnabled,
      content: isLinkSubmitted ? (
        <AITwinSubmitSuccess handleSubmitAnother={() => setIsLinkSubmitted(false)} />
      ) : (
        <AITwinSubmitLink
          error={linkInputError}
          setError={setLinkInputError}
          setLinkInput={setLinkInput}
          linkInput={linkInput}
          setIsLinkSubmitted={setIsLinkSubmitted}
        />
      ),
    },
    ...conditionalArray(gongFF && !isSalesRep, {
      id: AITwinItem.GONG,
      icon: <Icons icon={Icon.GONG} fill preserveSize />,
      title: 'Build from real call',
      className: 'bg-gong-purple-light',
      disabled: !isUploadModalEnabled,
      content: <AITwinGong />,
    }),
  ];

  return (
    <div className="flex flex-col gap-4">
      <Typography color={TextColor.SECONDARY} size={TypographySize.H5}>
        Practice Prospects based on real calls.
      </Typography>
      <Accordion items={items.map((item) => ({ ...item, content: <ItemWrapper>{item.content}</ItemWrapper> }))} />
    </div>
  );
};

export default AITwinTab;
