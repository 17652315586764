import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ANNUAL_REVENUE_RANGES,
  BACKGROUND_PROMPTS,
  CITIES,
  COMPANY_NAMES,
  COMPANY_SIZE_RANGES,
  DEPARTMENTS,
  INDUSTRIES,
  JOB_TITLES,
  LAST_NAMES,
  OBJECTIONS,
  PRIORITIES,
  PROSPECT_SLIDER_LIMITS,
  QUESTIONS,
  TTS_MODEL_OPTIONS,
  VOICE_OPTIONS,
} from '../../constants';
import { FundingRaised, Pronouns, ProspectSuccessResult, ProspectType, RemoteType, RoleType } from '../../types';
import {
  getRandomAge,
  getRandomElement,
  getRandomFirstName,
  getRandomNumOfDirectReports,
  getRandomScenarioItems,
  getRandomSliderValue,
  getRandomTenure,
} from '../../utils';

interface ProspectState {
  orgId: string;
  personal: {
    // Demographics
    firstName: { value: string; isUserModified?: boolean };
    lastName: { value: string; isUserModified?: boolean };
    age: { value?: string; isUserModified?: boolean };
    pronouns: { value?: Pronouns; isUserModified?: boolean };

    // Personality
    talkativenessLevel: { value: number; isUserModified?: boolean };
    thinkingStyle: { value: number; isUserModified?: boolean };
    empathyLevel: { value: number; isUserModified?: boolean };
    improvisationPreference: { value: number; isUserModified?: boolean };

    // Voice
    ttsModelId: { value?: string; isUserModified?: boolean };
    personaVoiceId: { value?: string; isUserModified?: boolean };
  };
  lead: {
    jobTitle: { value: string; isUserModified?: boolean };
    tenure: { value?: number; isUserModified?: boolean };
    roleType: { value?: RoleType; isUserModified?: boolean };
    department: { value?: string; isUserModified?: boolean };
    numOfDirectReports: { value?: number; isUserModified?: boolean };
  };
  account: {
    accountName: { value: string; isUserModified?: boolean };
    industry: { value: string; isUserModified?: boolean };
    hqLocation: { value?: string; isUserModified?: boolean };
    remoteType: { value?: RemoteType; isUserModified?: boolean };
    isMultiSite: { value?: boolean; isUserModified?: boolean };
    numOfEmployees: { value?: [number, number]; isUserModified?: boolean };
    annualRevenue: { value?: [number, number]; isUserModified?: boolean };
    fundingRaised: { value?: FundingRaised; isUserModified?: boolean };
  };
  scenario: {
    priorities: { value: string; isUserModified?: boolean }[];
    objections: { value: string; isUserModified?: boolean }[];
    questions: { value: string; isUserModified?: boolean }[];

    // Outcome
    successResult: { value?: ProspectSuccessResult; isUserModified?: boolean };
    successDifficultyLevel: { value: number; isUserModified?: boolean };

    // Scene
    backgroundAudioPrompt: { value?: string; isUserModified?: boolean };
    // TODO: Audio selection.
    // backgroundAudioName: { value?: string; isUserModified?: boolean };
  };
  configuration: {
    prospectType: ProspectType;
  };
  prompt: Record<string, never>;
}

const INITIAL_PROSPECT_STATE: ProspectState = {
  orgId: '',
  personal: {
    // Demographics
    firstName: { value: '' },
    lastName: { value: '' },
    age: {},
    pronouns: {},

    // Personality
    talkativenessLevel: { value: PROSPECT_SLIDER_LIMITS.default },
    thinkingStyle: { value: PROSPECT_SLIDER_LIMITS.default },
    empathyLevel: { value: PROSPECT_SLIDER_LIMITS.default },
    improvisationPreference: { value: PROSPECT_SLIDER_LIMITS.default },

    // Voice
    ttsModelId: {},
    personaVoiceId: {},
  },
  lead: {
    jobTitle: { value: '' },
    tenure: {},
    roleType: {},
    department: {},
    numOfDirectReports: {},
  },
  account: {
    accountName: { value: '' },
    industry: { value: '' },
    hqLocation: {},
    remoteType: {},
    isMultiSite: {},
    numOfEmployees: {},
    annualRevenue: {},
    fundingRaised: {},
  },
  scenario: {
    priorities: [],
    objections: [],
    questions: [],
    successResult: {},
    successDifficultyLevel: { value: PROSPECT_SLIDER_LIMITS.default },
    backgroundAudioPrompt: {},
  },
  configuration: {
    prospectType: ProspectType.REPS,
  },
  prompt: {},
};

// Create a slice for managing the prospect form state.
const prospectReducer = createSlice({
  name: 'prospect',
  initialState: INITIAL_PROSPECT_STATE,
  reducers: {
    // Resets the prospect form to its initial state.
    resetProspectForm: () => INITIAL_PROSPECT_STATE,
    setOrgId: (state, action: PayloadAction<string>) => {
      state.orgId = action.payload;
    },
    // -- Personal -- //
    // isUserModified: Whether the attribute was updated by the user.
    // If true, the attribute will be added to the list of user-updated attributes.
    // If false / undefined, the attribute was randomly selected and will be removed from the list of user-updated attributes.
    setFirstName: (state, action: PayloadAction<{ value: string; isUserModified?: boolean }>) => {
      state.personal.firstName = action.payload;
    },
    setLastName: (state, action: PayloadAction<{ value: string; isUserModified?: boolean }>) => {
      state.personal.lastName = action.payload;
    },
    setAge: (state, action: PayloadAction<{ value: string; isUserModified?: boolean }>) => {
      state.personal.age = action.payload;
    },
    setPronouns: (state, action: PayloadAction<{ value: Pronouns; isUserModified?: boolean }>) => {
      state.personal.pronouns = action.payload;
    },
    setTalkativenessLevel: (state, action: PayloadAction<{ value: number; isUserModified?: boolean }>) => {
      state.personal.talkativenessLevel = action.payload;
    },
    setThinkingStyle: (state, action: PayloadAction<{ value: number; isUserModified?: boolean }>) => {
      state.personal.thinkingStyle = action.payload;
    },
    setEmpathyLevel: (state, action: PayloadAction<{ value: number; isUserModified?: boolean }>) => {
      state.personal.empathyLevel = action.payload;
    },
    setImprovisationPreference: (state, action: PayloadAction<{ value: number; isUserModified?: boolean }>) => {
      state.personal.improvisationPreference = action.payload;
    },
    setTtsModelId: (state, action: PayloadAction<{ value?: string; isUserModified?: boolean }>) => {
      state.personal.ttsModelId = action.payload;
    },
    setPersonaVoiceId: (state, action: PayloadAction<{ value?: string; isUserModified?: boolean }>) => {
      state.personal.personaVoiceId = action.payload;
    },
    // Randomizes demographics.
    // Only randomizes fields that the user hasn't previously modified themselves.
    randomizeDemographics: (state) => {
      let newPronouns: Pronouns | undefined;
      if (!state.personal.pronouns?.isUserModified) {
        newPronouns = getRandomElement(Object.values(Pronouns));
        state.personal.pronouns = { value: newPronouns };
      }

      if (!state.personal.firstName.isUserModified) {
        const currentPronouns = newPronouns ?? state.personal.pronouns?.value;
        const randomFirstName = getRandomFirstName(currentPronouns);
        state.personal.firstName = { value: randomFirstName };
      }

      if (!state.personal.lastName.isUserModified) {
        const randomLastName = getRandomElement(LAST_NAMES);
        state.personal.lastName = { value: randomLastName };
      }

      if (!state.personal.age.isUserModified) {
        const randomAge = getRandomAge();
        state.personal.age = { value: randomAge.toString() };
      }
    },
    // Randomizes personality traits.
    // Only randomizes fields that the user hasn't previously modified themselves.
    randomizePersonality: (state) => {
      if (!state.personal.talkativenessLevel.isUserModified) {
        const randomValue = getRandomSliderValue();
        state.personal.talkativenessLevel = { value: randomValue };
      }

      if (!state.personal.thinkingStyle.isUserModified) {
        const randomValue = getRandomSliderValue();
        state.personal.thinkingStyle = { value: randomValue };
      }

      if (!state.personal.empathyLevel.isUserModified) {
        const randomValue = getRandomSliderValue();
        state.personal.empathyLevel = { value: randomValue };
      }

      if (!state.personal.improvisationPreference.isUserModified) {
        const randomValue = getRandomSliderValue();
        state.personal.improvisationPreference = { value: randomValue };
      }
    },
    // Randomizes voice.
    // Only randomizes fields that the user hasn't previously modified themselves.
    randomizeVoice: (state) => {
      if (!state.personal.ttsModelId.isUserModified) {
        const randomTtsModelId = getRandomElement(TTS_MODEL_OPTIONS).value;
        state.personal.ttsModelId = { value: randomTtsModelId };
      }

      if (!state.personal.personaVoiceId.isUserModified) {
        const randomVoiceId = getRandomElement(VOICE_OPTIONS).value;
        state.personal.personaVoiceId = { value: randomVoiceId };
      }
    },
    // -- Lead -- //
    setJobTitle: (state, action: PayloadAction<{ value: string; isUserModified?: boolean }>) => {
      state.lead.jobTitle = action.payload;
    },
    setTenure: (state, action: PayloadAction<{ value: number; isUserModified?: boolean }>) => {
      state.lead.tenure = action.payload;
    },
    setRoleType: (state, action: PayloadAction<{ value: RoleType; isUserModified?: boolean }>) => {
      state.lead.roleType = action.payload;
    },
    setDepartment: (state, action: PayloadAction<{ value: string; isUserModified?: boolean }>) => {
      state.lead.department = action.payload;
    },
    setNumOfDirectReports: (state, action: PayloadAction<{ value: number; isUserModified?: boolean }>) => {
      state.lead.numOfDirectReports = action.payload;
    },
    randomizeLead: (state) => {
      if (!state.lead.jobTitle.isUserModified) {
        const randomJobTitle = getRandomElement(JOB_TITLES);
        state.lead.jobTitle = { value: randomJobTitle };
      }

      if (!state.lead.tenure?.isUserModified) {
        const randomTenure = getRandomTenure();
        state.lead.tenure = { value: randomTenure };
      }

      if (!state.lead.roleType?.isUserModified) {
        const randomRoleType = getRandomElement(Object.values(RoleType));
        state.lead.roleType = { value: randomRoleType };
      }

      if (!state.lead.department?.isUserModified) {
        const randomDepartment = getRandomElement(DEPARTMENTS);
        state.lead.department = { value: randomDepartment };
      }

      if (!state.lead.numOfDirectReports?.isUserModified) {
        const randomNumOfDirectReports = getRandomNumOfDirectReports();
        state.lead.numOfDirectReports = { value: randomNumOfDirectReports };
      }
    },
    // -- Account -- //
    setAccountName: (state, action: PayloadAction<{ value: string; isUserModified?: boolean }>) => {
      state.account.accountName = action.payload;
    },
    setIndustry: (state, action: PayloadAction<{ value: string; isUserModified?: boolean }>) => {
      state.account.industry = action.payload;
    },
    setHQLocation: (state, action: PayloadAction<{ value: string; isUserModified?: boolean }>) => {
      state.account.hqLocation = action.payload;
    },
    setRemoteType: (state, action: PayloadAction<{ value: RemoteType; isUserModified?: boolean }>) => {
      state.account.remoteType = action.payload;
    },
    setIsMultiSite: (state, action: PayloadAction<{ value?: boolean; isUserModified?: boolean }>) => {
      state.account.isMultiSite = action.payload;
    },
    setNumOfEmployees: (state, action: PayloadAction<{ value?: [number, number]; isUserModified?: boolean }>) => {
      state.account.numOfEmployees = action.payload;
    },
    setAnnualRevenue: (state, action: PayloadAction<{ value?: [number, number]; isUserModified?: boolean }>) => {
      state.account.annualRevenue = action.payload;
    },
    setFundingRaised: (state, action: PayloadAction<{ value: FundingRaised; isUserModified?: boolean }>) => {
      state.account.fundingRaised = action.payload;
    },
    randomizeAccount: (state) => {
      if (!state.account.accountName?.isUserModified) {
        const randomCompanyName = getRandomElement(COMPANY_NAMES);
        state.account.accountName = { value: randomCompanyName };
      }

      if (!state.account.industry?.isUserModified) {
        const randomIndustry = getRandomElement(INDUSTRIES);
        state.account.industry = { value: randomIndustry };
      }

      if (!state.account.hqLocation?.isUserModified) {
        const randomHQLocation = getRandomElement(CITIES);
        state.account.hqLocation = { value: randomHQLocation };
      }

      if (!state.account.remoteType?.isUserModified) {
        const randomRemoteType = getRandomElement(Object.values(RemoteType));
        state.account.remoteType = { value: randomRemoteType };
      }

      if (!state.account.isMultiSite?.isUserModified) {
        const randomIsMultiSite = getRandomElement([true, false]);
        state.account.isMultiSite = { value: randomIsMultiSite };
      }

      if (!state.account.numOfEmployees?.isUserModified) {
        const randomNumOfEmployeesRange = getRandomElement(COMPANY_SIZE_RANGES);
        state.account.numOfEmployees = { value: randomNumOfEmployeesRange };
      }

      if (!state.account.annualRevenue?.isUserModified) {
        const randomAnnualRevenueRange = getRandomElement(ANNUAL_REVENUE_RANGES);
        state.account.annualRevenue = { value: randomAnnualRevenueRange };
      }

      if (!state.account.fundingRaised?.isUserModified) {
        const randomFundingRaised = getRandomElement(Object.values(FundingRaised));
        state.account.fundingRaised = { value: randomFundingRaised };
      }
    },
    setPriorities: (state, action: PayloadAction<{ value: string; isUserModified?: boolean }[]>) => {
      state.scenario.priorities = action.payload;
    },
    setObjections: (state, action: PayloadAction<{ value: string; isUserModified?: boolean }[]>) => {
      state.scenario.objections = action.payload;
    },
    setQuestions: (state, action: PayloadAction<{ value: string; isUserModified?: boolean }[]>) => {
      state.scenario.questions = action.payload;
    },
    setSuccessResult: (state, action: PayloadAction<{ value?: ProspectSuccessResult; isUserModified?: boolean }>) => {
      state.scenario.successResult = action.payload;
    },
    setSuccessDifficultyLevel: (state, action: PayloadAction<{ value: number; isUserModified?: boolean }>) => {
      state.scenario.successDifficultyLevel = action.payload;
    },
    setBackgroundAudioPrompt: (state, action: PayloadAction<{ value?: string; isUserModified?: boolean }>) => {
      state.scenario.backgroundAudioPrompt = action.payload;
    },
    randomizePriorities: (state) => {
      state.scenario.priorities = getRandomScenarioItems(PRIORITIES, state.scenario.priorities);
    },
    randomizeObjections: (state) => {
      state.scenario.objections = getRandomScenarioItems(OBJECTIONS, state.scenario.objections);
    },
    randomizeQuestions: (state) => {
      state.scenario.questions = getRandomScenarioItems(QUESTIONS, state.scenario.questions);
    },
    randomizeOutcome: (state) => {
      if (!state.scenario.successResult.isUserModified) {
        const randomSuccessResult = getRandomElement(Object.values(ProspectSuccessResult));
        state.scenario.successResult = { value: randomSuccessResult };
      }

      if (!state.scenario.successDifficultyLevel.isUserModified) {
        const randomSuccessDifficulty = getRandomSliderValue();
        state.scenario.successDifficultyLevel = { value: randomSuccessDifficulty };
      }
    },
    randomizeScene: (state) => {
      if (!state.scenario.backgroundAudioPrompt.isUserModified) {
        const randomBackgroundAudioPrompt = getRandomElement(BACKGROUND_PROMPTS);
        state.scenario.backgroundAudioPrompt = { value: randomBackgroundAudioPrompt };
      }

      // TODO: Randomize audio selection.
    },
    setProspectType: (state, action: PayloadAction<ProspectType>) => {
      state.configuration.prospectType = action.payload;
    },
  },
});

export const {
  resetProspectForm,
  setOrgId,
  setFirstName,
  setLastName,
  setAge,
  setPronouns,
  setTalkativenessLevel,
  setThinkingStyle,
  setEmpathyLevel,
  setImprovisationPreference,
  setTtsModelId,
  setPersonaVoiceId,
  randomizeDemographics,
  randomizePersonality,
  randomizeVoice,
  setJobTitle,
  setTenure,
  setRoleType,
  setDepartment,
  setNumOfDirectReports,
  randomizeLead,
  setAccountName,
  setIndustry,
  setHQLocation,
  setRemoteType,
  setIsMultiSite,
  setNumOfEmployees,
  setAnnualRevenue,
  setFundingRaised,
  randomizeAccount,
  setPriorities,
  setObjections,
  setQuestions,
  setSuccessResult,
  setSuccessDifficultyLevel,
  setBackgroundAudioPrompt,
  randomizePriorities,
  randomizeObjections,
  randomizeQuestions,
  randomizeOutcome,
  randomizeScene,
  setProspectType,
} = prospectReducer.actions;

export default prospectReducer.reducer;
