import { TextColor } from '../../../../../types';
import { Typography, TypographySize, TypographyWeight } from '../../../../shared';

interface IntegrationSectionHeaderProps {
  title: string;
  description: React.ReactNode;
}

const IntegrationSectionHeader = ({ title, description }: IntegrationSectionHeaderProps) => {
  return (
    <div className="flex flex-col gap-1">
      <Typography size={TypographySize.H5} weight={TypographyWeight.SEMI_BOLD}>
        {title}
      </Typography>
      <Typography size={TypographySize.H5} color={TextColor.SECONDARY}>
        {description}
      </Typography>
    </div>
  );
};

export default IntegrationSectionHeader;
