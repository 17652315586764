import { Select, TextInput, TextInputType, Typography, TypographySize } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { randomizeDemographics, setAge, setFirstName, setLastName, setPronouns } from '../../../../redux/reducers';
import { Pronouns, TextColor } from '../../../../types';
import TabSection from '../TabSection';

const PRONOUNS_SELECT_WIDTH = 210;

const Demographics = () => {
  const dispatch = useAppDispatch();
  const { firstName, lastName, age, pronouns } = useAppSelector((state) => state.prospect.personal);

  const pronounOptions = Object.values(Pronouns).map((value) => ({ label: value, value }));
  const selectedPronounOption = pronounOptions.find((option) => option.value === pronouns?.value);

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFirstName = e.target.value;
    dispatch(setFirstName({ value: newFirstName, isUserModified: !!newFirstName }));
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newLastName = e.target.value;
    dispatch(setLastName({ value: newLastName, isUserModified: !!newLastName }));
  };

  const handleAgeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAge = e.target.value;
    dispatch(setAge({ value: newAge, isUserModified: !!newAge }));
  };

  const handlePronounsChange = (value?: string) => {
    dispatch(setPronouns({ value: value as Pronouns, isUserModified: !!value }));
  };

  const fields = [
    {
      label: 'First name*',
      content: <TextInput value={firstName.value} onChange={handleFirstNameChange} />,
    },
    {
      label: 'Last name*',
      content: <TextInput value={lastName.value} onChange={handleLastNameChange} />,
    },
    {
      label: 'Age',
      content: <TextInput value={age.value} onChange={handleAgeChange} type={TextInputType.NUMBER} />,
    },
    {
      label: 'Pronouns',
      content: (
        <Select
          clearable
          options={pronounOptions}
          selected={selectedPronounOption}
          onChange={handlePronounsChange}
          width={PRONOUNS_SELECT_WIDTH}
        />
      ),
    },
  ];

  return (
    <TabSection title="Demographics" onRandomize={() => dispatch(randomizeDemographics())}>
      <div className="flex gap-2">
        {fields.map((field) => (
          <div key={field.label} className="flex flex-col gap-2">
            <Typography size={TypographySize.CAPTION} color={TextColor.SECONDARY} className="select-none">
              {field.label}
            </Typography>
            {field.content}
          </div>
        ))}
      </div>
    </TabSection>
  );
};

export default Demographics;
