import { ButtonColor, Icon, TextButton } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { randomizeQuestions, setQuestions } from '../../../../redux/reducers';
import TabSection from '../TabSection';
import ScenarioListItem from './ScenarioListItem';

const Questions = () => {
  const dispatch = useAppDispatch();
  const questions = useAppSelector((state) => state.prospect.scenario.questions) || [];

  // Adds an empty question field.
  const onAddQuestion = () => {
    dispatch(setQuestions([...questions, { value: '' }]));
  };

  return (
    <TabSection title="Questions" onRandomize={() => dispatch(randomizeQuestions())}>
      {questions.map((question, index) => (
        <ScenarioListItem
          key={index}
          index={index}
          items={questions}
          value={question.value}
          setItems={(newItems) => dispatch(setQuestions(newItems))}
        />
      ))}
      <TextButton text="Add question" startIcon={Icon.PLUS} color={ButtonColor.SECONDARY} onClick={onAddQuestion} />
    </TabSection>
  );
};

export default Questions;
