import clsx from 'clsx';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { NavBar, Sidebar } from '../../components';
import { AppRoutes } from '../../constants';
import { useAppDispatch, useIntercom } from '../../hooks';
import { setCurrentCallDetails } from '../../redux/reducers';
import Banners from './Banners';
import Modals from './Modals';

function Layout() {
  const location = useLocation();
  const isProspectPage = location.pathname.includes(AppRoutes.PROSPECT);

  const dispatch = useAppDispatch();

  useIntercom();

  // Reset the call details when the user navigates away from the call history page.
  useEffect(() => {
    if (!location.pathname.includes(AppRoutes.CALL_HISTORY)) {
      dispatch(setCurrentCallDetails(undefined));
    }
  }, [location, dispatch]);

  return (
    <div className="flex h-screen flex-col">
      <Banners />
      <NavBar />
      <div className="flex flex-1 overflow-hidden">
        {!isProspectPage && <Sidebar />}
        <div className={clsx('flex-1 overflow-auto bg-base-0', !isProspectPage && 'p-4')}>
          <Outlet />
        </div>
      </div>
      <Modals />
    </div>
  );
}

export default Layout;
