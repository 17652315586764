import { useCallback, useEffect, useState } from 'react';
import { MousePosition, TextColor } from '../../../types';
import { DataTable } from '../DataTable';
import { Dropdown, DropdownContent } from '../Dropdown';
import { DropdownItem } from '../DropdownItem';
import { Icons } from '../Icons';
import { Typography } from '../Typography';
import { DataTableWithActionsProps } from './DataTableWithActions.types';

/** The DataTableWithActions component displays a data table with actionable items for each row. */
const DataTableWithActions = <T extends object>({
  actions = [],
  isLoading,
  setClickedRowIndex,
  setHoveredRowIndex,
  activeRowIndex,
  clickedRowIndex,
  ...dataTableProps
}: DataTableWithActionsProps<T>) => {
  // State to track the position of the click event.
  const [clickPosition, setClickPosition] = useState<MousePosition | undefined>(undefined);

  const isActionsPopoverOpen = clickedRowIndex !== undefined;
  const closeActionsPopover = () => setClickedRowIndex(undefined);

  useEffect(() => {
    if (isLoading && isActionsPopoverOpen) {
      closeActionsPopover();
    }
  }, [isLoading]);

  // Handle row click event to set the click position and row index.
  const handleRowClick = useCallback(
    (e: React.MouseEvent, index: number) => {
      if (actions.length) {
        setClickPosition({ x: e.clientX, y: e.clientY });
        setClickedRowIndex(index === clickedRowIndex ? undefined : index);
      }
    },
    [actions.length, clickedRowIndex, setClickPosition, setClickedRowIndex]
  );

  return (
    <>
      <DataTable
        isLoading={isLoading}
        onRowHover={setHoveredRowIndex}
        onRowClick={handleRowClick}
        onRowRightClick={handleRowClick}
        activeRowIndices={[clickedRowIndex, activeRowIndex].filter((index): index is number => index !== undefined)}
        {...dataTableProps}
      />
      {!!actions.length && (
        <Dropdown position={clickPosition} open={isActionsPopoverOpen} onOpenChange={closeActionsPopover}>
          <DropdownContent>
            {actions.map(({ icon, iconColor: iconColorProp, iconFill, label, onClick }) => {
              const iconColor = onClick && !iconColorProp ? TextColor.PRIMARY : iconColorProp;
              return (
                <DropdownItem
                  key={label}
                  icon={<Icons icon={icon} color={iconColor} fill={iconFill} />}
                  label={onClick ? <Typography color={TextColor.PRIMARY}>{label}</Typography> : label}
                  onClick={onClick}
                />
              );
            })}
          </DropdownContent>
        </Dropdown>
      )}
    </>
  );
};

export default DataTableWithActions;
