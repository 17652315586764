import {
  MAX_RANDOM_SCENARIO_ITEMS,
  NEUTRAL_FIRST_NAMES,
  PRONOUNS_TO_FIRST_NAMES,
  PROSPECT_AGE_VARIANCE,
  PROSPECT_AVERAGE_AGE,
  PROSPECT_MAX_AGE,
  PROSPECT_MAX_NUM_OF_DIRECT_REPORTS,
  PROSPECT_MAX_TENURE,
  PROSPECT_MIN_AGE,
  PROSPECT_MIN_NUM_OF_DIRECT_REPORTS,
  PROSPECT_MIN_TENURE,
  PROSPECT_NUM_OF_DIRECT_REPORTS_AVERAGE,
  PROSPECT_NUM_OF_DIRECT_REPORTS_VARIANCE,
  PROSPECT_REQUIRED_FIELDS,
  PROSPECT_SLIDER_LIMITS,
  PROSPECT_TENURE_AVERAGE,
  PROSPECT_TENURE_VARIANCE,
} from '../constants';
import { RootState } from '../redux/store';
import { Pronouns, ProspectPageTabs } from '../types';
import { getRandomElement, getRandomElements } from './object.utils';

/**
 * Helper function to generate a normally distributed random number using Box-Muller transform
 * and clamps the result between the min and max values.
 */
const generateNormalRandom = (average: number, max: number, min: number, variance: number) => {
  const u1 = Math.random();
  const u2 = Math.random();
  const z = Math.sqrt(-2 * Math.log(u1)) * Math.cos(2 * Math.PI * u2);
  const scaledZ = Math.round(z * variance + average);
  return Math.max(min, Math.min(max, scaledZ));
};

/** Generates a random slider value. */
export const getRandomSliderValue = () => {
  const { max, min, step } = PROSPECT_SLIDER_LIMITS;
  // Range of values.
  const range = max - min;
  // Generate a random value within the range.
  const randomValue = min + Math.random() * range;
  // Round to the nearest step.
  const roundedValue = Math.round(randomValue / step) * step;
  // Determine the number of decimal places in the step.
  const decimalPlaces = step.toString().split('.')[1]?.length || 0;
  // Return the rounded value formatted to the same number of decimal places as the step.
  return parseFloat(roundedValue.toFixed(decimalPlaces));
};

/** Generates a random first name based on the given pronouns. */
export const getRandomFirstName = (pronouns?: Pronouns) => {
  const firstNames = pronouns ? PRONOUNS_TO_FIRST_NAMES[pronouns] : NEUTRAL_FIRST_NAMES;
  return getRandomElement(firstNames);
};

/** Generates a random age between the min and max ages. */
export const getRandomAge = () => {
  return generateNormalRandom(PROSPECT_AVERAGE_AGE, PROSPECT_MAX_AGE, PROSPECT_MIN_AGE, PROSPECT_AGE_VARIANCE);
};

/** Checks if the required fields for the given tab are filled. */
export const areRequiredFieldsFilled = (state: RootState, activeTab: ProspectPageTabs): boolean => {
  const requiredFields = PROSPECT_REQUIRED_FIELDS[activeTab];
  const fields = state.prospect[activeTab];

  return requiredFields.every((field) => {
    const fieldValue = fields[field as keyof typeof fields] as { value?: number | string };
    return typeof fieldValue === 'object' && 'value' in fieldValue && fieldValue.value;
  });
};

/** Generates a random tenure between the min and max tenures. */
export const getRandomTenure = () => {
  return generateNormalRandom(
    PROSPECT_TENURE_AVERAGE,
    PROSPECT_MAX_TENURE,
    PROSPECT_MIN_TENURE,
    PROSPECT_TENURE_VARIANCE
  );
};

/** Generates a random number of direct reports between the min and max number of direct reports. */
export const getRandomNumOfDirectReports = () => {
  return generateNormalRandom(
    PROSPECT_NUM_OF_DIRECT_REPORTS_AVERAGE,
    PROSPECT_MAX_NUM_OF_DIRECT_REPORTS,
    PROSPECT_MIN_NUM_OF_DIRECT_REPORTS,
    PROSPECT_NUM_OF_DIRECT_REPORTS_VARIANCE
  );
};

/** Generates a random set of scenario items. */
export const getRandomScenarioItems = (
  allItems: string[],
  currentItems: { value: string; isUserModified?: boolean }[]
) => {
  // Get the items that the user has modified.
  const userModified = currentItems.filter((p) => p.isUserModified);

  // Calculate how many more items we need to add to reach the maximum number of randomly generated items.
  const numToAdd = MAX_RANDOM_SCENARIO_ITEMS - userModified.length;

  // Determine the remaining items that we can randomize from.
  const remainingItems = allItems.filter((item) => !userModified.some((i) => i.value === item));

  // Get the random items.
  const randomItems = getRandomElements(remainingItems, numToAdd).map((value) => ({ value }));

  // Replace non-user-modified items with nulls to keep the user-modified items at the same index.
  const parsedItems = currentItems.map((item) => (item.isUserModified ? item : null));

  // Replace nulls with random items.
  const newItems = parsedItems
    .map((item) => item ?? randomItems.shift())
    .filter((item): item is NonNullable<typeof item> => item != null);

  // Return the new items with empty values removed.
  return [...newItems, ...randomItems].filter((item) => item.value);
};
