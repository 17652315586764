import { TextColor } from '../../../../../../types';
import { TypographySize, TypographyWeight, Typography } from '../../../../../shared';

interface SeatCardProps {
  title: string;
  usedSeats: number;
  availableSeats: number;
}

const SeatCard = ({ title, usedSeats, availableSeats }: SeatCardProps) => (
  <div className="flex w-full flex-col items-center gap-1 rounded-lg border border-base-200 p-8">
    <Typography size={TypographySize.H5}>{title}</Typography>
    <Typography size={TypographySize.H1} weight={TypographyWeight.SEMI_BOLD}>
      {usedSeats}
    </Typography>
    <Typography size={TypographySize.CAPTION} color={TextColor.SECONDARY}>
      {availableSeats} more available
    </Typography>
  </div>
);

export default SeatCard;
