import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useAppDispatch, useToast, useWebCall } from '../../../hooks';
import { closeWebCall, setCurrentCallDetails, WebCallData } from '../../../redux/reducers';
import { ComponentSize } from '../../../types';
import { AlertType, Spinner } from '../../shared';
import WebCallModalContent from './WebCallModalContent';
import WebCallModalWrapper from './WebCallModalWrapper';

interface WebCallModalProps {
  currWebCall: WebCallData;
}

const WebCallModal = ({ currWebCall }: WebCallModalProps) => {
  // Initially true to show the loading spinner while the call starts.
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useAppDispatch();

  const { showToast } = useToast();
  const { isAudioInputError, isConnectionError, isCalling, isConnectingToMic, ...webCallProps } = useWebCall();

  // Show the loading spinner as the call starts and while the mic is connecting..
  useEffect(() => {
    if (isCalling) {
      setIsLoading(isConnectingToMic);
    }
  }, [isCalling, isConnectingToMic]);

  // Handles closing the media player on opening the web call modal.
  useEffect(() => {
    if (currWebCall) {
      dispatch(setCurrentCallDetails(undefined));
    }
  }, [currWebCall]);

  // Handles closing the web call modal and showing a toast message if there was an error connecting to the mic.
  useEffect(() => {
    if (isAudioInputError) {
      dispatch(closeWebCall());
      showToast({
        delay: 5000,
        title: 'Problem connecting to mic',
        message: 'There was a problem connecting to your microphone. Please try again.',
        type: AlertType.ERROR,
      });
    }
  }, [isAudioInputError, dispatch, showToast]);

  return (
    <WebCallModalWrapper>
      <div className="fixed inset-0 z-50 flex h-full items-end">
        {/* Overlay - Only allow clicking the overlay to close the modal if the call is not in progress. */}
        <div
          className="absolute inset-0 bg-neutral bg-opacity-40"
          onClick={isCalling ? undefined : () => dispatch(closeWebCall())}
        />
        <div className="relative z-50 w-full border-t border-gray-200 bg-base-0 p-8">
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center">
              <Spinner size={ComponentSize.MEDIUM} />
            </div>
          )}
          <div className={clsx(isLoading && 'invisible')}>
            <WebCallModalContent
              currWebCall={currWebCall}
              isCalling={isCalling}
              isError={isConnectionError}
              {...webCallProps}
            />
          </div>
        </div>
      </div>
    </WebCallModalWrapper>
  );
};

export default WebCallModal;
