import {
  ButtonColor,
  ButtonVariant,
  Divider,
  Icon,
  Icons,
  Spinner,
  TextButton,
  ConfirmModal,
} from '../../../../components';
import { ComponentSize, ScorecardAnswer } from '../../../../types';
import {
  useCreateScorecardMutation,
  useDeleteScorecardMutation,
  useUpdateScorecardMutation,
} from '../../../../services';
import { useParams } from 'react-router-dom';
import { useHandleApiResponse } from '../../../../hooks';
import { useCallback, useState } from 'react';

const CREATE_ERROR_MSG = 'Failed to add scorecard.';
const CREATE_SUCCESS_MSG = 'Scorecard added successfully.';

const UPDATE_ERROR_MSG = 'Failed to update scorecard.';
const UPDATE_SUCCESS_MSG = 'Scorecard updated successfully.';

const DELETE_ERROR_MSG = 'Failed to delete scorecard.';
const DELETE_SUCCESS_MSG = 'Scorecard deleted successfully.';

interface CallScorecardActionButtonsProps {
  /** Function to close the scorecard */
  handleCloseScorecard: () => void;
  /** Function to clear all answers */
  handleClearAnswers: () => void;
  /** Flag indicating if the scorecard is AI scoreable */
  isAiScoreable: boolean;
  /** The ID of the existing scorecard (if editing) */
  scorecardId?: string;
  /** The ID of the scorecard template (if creating)*/
  scorecardTemplateId?: string;
  /** The name of the scorecard to be deleted */
  scorecardName?: string;
  /** The answers for the scorecard to be saved */
  answers?: ScorecardAnswer[];
}

const CallScorecardActionButtons = ({
  scorecardId,
  scorecardTemplateId,
  scorecardName,
  isAiScoreable,
  handleCloseScorecard,
  handleClearAnswers,
  answers,
}: CallScorecardActionButtonsProps) => {
  // Mutations
  const [createScorecard] = useCreateScorecardMutation();
  const [updateScorecard, { isLoading: isUpdatingScorecard }] = useUpdateScorecardMutation();
  const [deleteScorecard, { isLoading: isDeletingScorecard }] = useDeleteScorecardMutation();

  // Hooks
  const handleApiResponse = useHandleApiResponse();

  // Params
  const { callSid } = useParams();

  // State
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  // Both AI scoring and saving the scorecard use the same endpoint, so we need to track both states
  const [isAiScoring, setIsAiScoring] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  // If no scorecard Id, means that this scorecard is new/being added for the first time
  const isNewScorecard = !scorecardId;

  /** Handles the creation of a new scorecard */
  const handleCreateScorecard = useCallback(async () => {
    if (!callSid || !scorecardTemplateId) return;
    setIsSaving(true);
    try {
      const response = await createScorecard({
        callSid,
        scorecardTemplateId: scorecardTemplateId,
        answers: answers || [],
      });
      handleApiResponse({
        response,
        errorMsg: CREATE_ERROR_MSG,
        successMsg: CREATE_SUCCESS_MSG,
        onSuccess: handleCloseScorecard,
      });
    } catch (error) {
      console.error(`${CREATE_ERROR_MSG}: ${error}`);
    }
    setIsSaving(false);
  }, [callSid, scorecardTemplateId, answers, createScorecard, handleApiResponse, handleCloseScorecard]);

  /** Handles the editing of an existing scorecard */
  const handleEditScorecard = useCallback(async () => {
    if (!scorecardId || !callSid) return;
    try {
      const response = await updateScorecard({
        id: scorecardId,
        callSid: callSid || '',
        answers: answers || [],
      });
      handleApiResponse({
        response,
        errorMsg: UPDATE_ERROR_MSG,
        successMsg: UPDATE_SUCCESS_MSG,
        onSuccess: handleCloseScorecard,
      });
    } catch (error) {
      console.error(`${UPDATE_ERROR_MSG}: ${error}`);
    }
  }, [scorecardId, callSid, answers, updateScorecard, handleApiResponse, handleCloseScorecard]);

  /** Handles the scoring of a new scorecard with AI */
  const handleScoreWithAi = useCallback(async () => {
    if (!callSid || !scorecardTemplateId) return;
    setIsAiScoring(true);
    try {
      const response = await createScorecard({
        callSid,
        scorecardTemplateId: scorecardTemplateId,
        answers: [],
        isScoredByAI: true,
      });
      handleApiResponse({
        response,
        errorMsg: CREATE_ERROR_MSG,
        successMsg: CREATE_SUCCESS_MSG,
      });
    } catch (error) {
      console.error(`${CREATE_ERROR_MSG}: ${error}`);
    }
    setIsAiScoring(false);
  }, [callSid, scorecardTemplateId, createScorecard, handleApiResponse]);

  /** Handles the deletion of an existing scorecard */
  const handleDeleteOrClearScorecard = useCallback(async () => {
    // If scorecard is new, clear the answers
    if (isNewScorecard) {
      handleClearAnswers();
      setIsConfirmModalOpen(false);
      return;
    }

    if (!scorecardId || !callSid) return;

    try {
      const response = await deleteScorecard({ id: scorecardId, callSid });
      handleApiResponse({
        response,
        errorMsg: DELETE_ERROR_MSG,
        successMsg: DELETE_SUCCESS_MSG,
        onSuccess: handleCloseScorecard,
      });
    } catch (error) {
      console.error(`${DELETE_ERROR_MSG}: ${error}`);
    }
  }, [scorecardId, deleteScorecard, handleApiResponse, handleCloseScorecard]);

  const destructiveAction = isNewScorecard ? 'Clear' : 'Delete';

  const hasNewAnswers = !!answers?.length;

  const destructiveButtonConfirmText = (
    <>
      Are you sure you want to {destructiveAction.toLowerCase()}&nbsp;
      <span className="font-medium">{scorecardName}</span>? This action cannot be undone.
    </>
  );

  const aiScoringButtonIcon = isAiScoring ? (
    <Spinner size={ComponentSize.X_SMALL} className="text-neutral-content" />
  ) : (
    <Icons icon={Icon.WAND} />
  );

  return (
    <div className="sticky bottom-0 flex w-full flex-col justify-end gap-2 bg-white">
      <Divider className="!-ml-6 -mr-6" />
      <div className="flex w-full justify-end gap-2 pb-2">
        <TextButton
          text={destructiveAction}
          onClick={() => setIsConfirmModalOpen(true)}
          variant={ButtonVariant.OUTLINE}
          color={ButtonColor.DESTRUCTIVE}
          size={ComponentSize.X_SMALL}
          loading={isDeletingScorecard}
        />

        {isAiScoreable && isNewScorecard && (
          <TextButton
            text="Score with AI"
            variant={ButtonVariant.OUTLINE}
            onClick={handleScoreWithAi}
            color={ButtonColor.AI}
            size={ComponentSize.X_SMALL}
            startIcon={aiScoringButtonIcon}
          />
        )}

        <TextButton
          text={isNewScorecard ? 'Save' : 'Edit'}
          onClick={isNewScorecard ? handleCreateScorecard : handleEditScorecard}
          size={ComponentSize.X_SMALL}
          loading={isSaving || isUpdatingScorecard}
          // Disable save button if no new answers have been provided
          disabled={!hasNewAnswers}
        />
      </div>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        buttonText={destructiveAction}
        setIsOpen={setIsConfirmModalOpen}
        onConfirm={handleDeleteOrClearScorecard}
        isLoading={isDeletingScorecard}
        title={`${destructiveAction} scorecard`}
        confirmText={destructiveButtonConfirmText}
        destructive
      />
    </div>
  );
};

export default CallScorecardActionButtons;
