import { show as openIntercom } from '@intercom/messenger-js-sdk';
import { useState } from 'react';
import { TextColor } from '../../../types';
import { ButtonColor, Icon, IconButton, Icons, TextButton } from '../../shared';

interface WebCallActionsProps {
  isHangingUp: boolean;
  isMuted: boolean;
  endCall: () => void;
  mute: () => void;
  unmute: () => void;
}

const WebCallActions = ({ isHangingUp, isMuted, endCall, mute, unmute }: WebCallActionsProps) => {
  const [isHoveringMute, setIsHoveringMute] = useState(false);

  const icon = isMuted ? Icon.MIC_OFF : Icon.MIC_ON;
  const iconColor = isHoveringMute ? TextColor.DESTRUCTIVE : undefined;

  const onMuteClick = () => {
    if (isMuted) unmute();
    else mute();
  };

  return (
    <div className="flex items-center justify-end gap-2">
      <IconButton
        icon={<Icons icon={icon} color={iconColor} />}
        color={ButtonColor.SECONDARY}
        onClick={onMuteClick}
        onHover={(hover) => setIsHoveringMute(hover)}
        active={isMuted}
        tooltip={isMuted ? 'Unmute' : 'Mute'}
      />
      <TextButton
        text="Report issue"
        startIcon={Icon.REPORT}
        color={ButtonColor.SECONDARY}
        onClick={openIntercom}
        fullWidth
      />
      <TextButton text="End call" color={ButtonColor.DESTRUCTIVE} onClick={endCall} fullWidth disabled={isHangingUp} />
    </div>
  );
};

export default WebCallActions;
