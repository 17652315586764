import { Call } from '../../../../types';
import CallStatsPanel from './CallStatsPanel';
import SummaryRow from './SummaryRow';
import { useMemo } from 'react';
import { Typography, TypographySize, TypographyWeight } from '../../../../components';
import { SUMMARY_WIDTH } from '../../../../constants';
import TabEmptyState from '../TabEmptyState';

interface SummaryTabProps {
  seekAudio: (startTime: number) => void;
  call: Call;
}

const SummaryTab = ({ call, seekAudio }: SummaryTabProps) => {
  // Memoized list of chapters from the call data.
  const chapters = useMemo(() => {
    // Check if callChapters exist and are not empty
    if (call?.callChapters && call.callChapters.length > 0) {
      return call.callChapters.map((chapter) => ({
        title: chapter.title,
        startTime: chapter.startTime,
      }));
    }

    // If callChapters are not available or empty, fall back to callSpeechCategories for backward compatibility
    return (call?.callSpeechCategories || []).map((category) => ({
      title: category.summary,
      startTime: category.startTime,
    }));
  }, [call?.callChapters, call?.callSpeechCategories]);

  if (!chapters.length) {
    return <TabEmptyState text="No summary available" />;
  }

  return (
    <div className="flex w-full gap-8 px-4">
      <CallStatsPanel {...call} />
      <div className="flex w-full flex-col gap-4" style={{ maxWidth: SUMMARY_WIDTH }}>
        <Typography size={TypographySize.H3} weight={TypographyWeight.SEMI_BOLD}>
          Chapters
        </Typography>
        {chapters.map((chapter) => (
          <SummaryRow
            key={chapter.startTime}
            seekToAudio={() => seekAudio(chapter.startTime)}
            title={chapter.title}
            startTime={chapter.startTime}
          />
        ))}
      </div>
    </div>
  );
};

export default SummaryTab;
