import { SelectOption } from './BaseSelect';

/**
 * Finds a single option from the options list based on the value passed in.
 * If the value is not in the options list, it is ignored.
 */
export const findOptionByValue = (options: SelectOption[], value?: string | number) =>
  options.find((option) => option.value === value);

/**
 * Finds a list of options from the options list based on the values passed in.
 * If a value is not in the options list, it is ignored.
 */
export const findOptionsByValues = (options: SelectOption[], values?: string[]): SelectOption[] =>
  options.filter((option) => values?.includes(option.value));
