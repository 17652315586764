import TabHeader from '../TabHeader';
import GongOAuth from './ConnectGongOAuth';
import GongAPIKey from './ConnectGonyAPIKey';

const IntegrationsTab = () => {
  return (
    <div className="flex flex-col gap-6">
      <TabHeader title="Integrations" />
      <GongOAuth />
      <GongAPIKey />
    </div>
  );
};

export default IntegrationsTab;
