import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../../../constants';
import { useAppDispatch } from '../../../../../hooks';
import { closeCreateProspectModal } from '../../../../../redux/reducers';
import { TextColor } from '../../../../../types';
import { TextButton, Typography, TypographySize } from '../../../../shared';

const ProspectDesignerItem = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Close the create prospect modal and navigate to the prospect page.
  const handleStartDesigner = () => {
    dispatch(closeCreateProspectModal());
    navigate(`${AppRoutes.PRACTICE}/prospect`);
  };

  return (
    <div className="flex items-center justify-between">
      <Typography size={TypographySize.H5} color={TextColor.SECONDARY}>
        Build your own prospect using a guided wizard.
      </Typography>
      <TextButton text="Start designer" onClick={handleStartDesigner} />
    </div>
  );
};

export default ProspectDesignerItem;
