import { useCallback, useEffect } from 'react';
import { useGetCallsMutation, useGetPracticeCallsAnalyticsMutation } from '../../services/api.services';
import { useGetFiltersFromParams } from '../../hooks';
import { formatMinutesToDuration, getDateFilter } from '../../utils';
import { AnalyticsFilters, ComponentSize } from '../../types';
import { Spinner } from '../../components';
import { AnalyticsCard } from './AnalyticsCard';
import AnalyticsEmptyState from './AnalyticsEmptyState';
import { useNavigate } from 'react-router-dom';
import { AppRoutes, DEFAULT_ANALYTICS_DATE_RANGE } from '../../constants';

const AnalyticsPage = () => {
  const navigate = useNavigate();
  const [getPracticeCallsAnalytics, { data: analyticsData, isLoading: isLoadingAnalytics }] =
    useGetPracticeCallsAnalyticsMutation();
  const [getCalls, { data: callsData }] = useGetCallsMutation();

  const filters: AnalyticsFilters = useGetFiltersFromParams();

  const fetchAnalyticsData = useCallback(() => {
    const dateRange = getDateFilter(filters.dateRange ? filters.dateRange : DEFAULT_ANALYTICS_DATE_RANGE) || [];

    getPracticeCallsAnalytics({
      dateRange,
      tags: filters.tags,
      callers: filters.caller,
    });
  }, [getPracticeCallsAnalytics, filters]);

  // Fetch calls data
  // TODO: replace api call with redux once we have them stored there
  useEffect(() => {
    getCalls({});
  }, [getCalls]);

  // Fetch analytics data
  useEffect(() => {
    fetchAnalyticsData();
  }, [fetchAnalyticsData]);

  // Sort data by call count in descending order
  const callsChartData = analyticsData?.callers
    .map((caller) => ({
      label: caller.name,
      value: caller.callCount,
    }))
    .sort((a, b) => b.value - a.value);

  // Backend returns data sorted descendingly by talk time, so no need to sort it.
  const talkTimeChartData = analyticsData?.callers.map((caller) => ({
    label: caller.name,
    value: caller.talkTime,
  }));

  if (isLoadingAnalytics) {
    return (
      <div className="flex h-full items-center justify-center">
        <Spinner size={ComponentSize.SMALL} />
      </div>
    );
  }

  // If there are calls but no analytics data, show the no calls for the selected filters message
  if (callsData?.calls.length && !analyticsData?.callers.length) {
    return <AnalyticsEmptyState text="No calls found for the selected filters" />;
  }

  // If there are no calls, show the make calls message
  if (!callsData?.calls.length) {
    return (
      <AnalyticsEmptyState
        text="Make calls to start seeing insights"
        showCallButton
        onButtonClick={() => navigate(AppRoutes.PRACTICE)}
      />
    );
  }

  // Determine the unit for the total talk time title based on the total talk time value
  const totalTalkTimeTitleUnit = analyticsData?.totalTalkTime && analyticsData?.totalTalkTime > 59 ? 'hrs' : 'mins';

  return (
    <div className="flex flex-col gap-4">
      <AnalyticsCard
        title="Practice calls"
        value={analyticsData?.totalCalls || 0}
        chartTitle="Count of practice calls by caller"
        data={callsChartData || []}
      />
      <AnalyticsCard
        title={`Total talk time (${totalTalkTimeTitleUnit})`}
        value={analyticsData?.totalTalkTime || 0}
        chartTitle="Total talk time by caller"
        data={talkTimeChartData || []}
        valueFormatter={formatMinutesToDuration}
      />
    </div>
  );
};

export default AnalyticsPage;
