import { BaseColor, StatusColor, TagColor } from '../types';

/** Checks if the color is a valid BaseColor. */
export const isBaseColor = (color: string): color is BaseColor => {
  return Object.values(BaseColor).includes(color as BaseColor);
};

/** Checks if the color is a valid StatusColor. */
export const isStatusColor = (color: string): color is StatusColor => {
  return Object.values(StatusColor).includes(color as StatusColor);
};

/** Checks if the color is a valid TagColor. */
export const isTagColor = (color: string): color is TagColor => {
  return Object.values(TagColor).includes(color as TagColor);
};
