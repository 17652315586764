import { Dispatch, SetStateAction, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConfirmModal } from '../modals';
import { AppRoutes, DEFAULT_ANALYTICS_DATE_RANGE } from '../../constants';

interface ClearFiltersDialogProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const ClearFiltersDialog = ({ isOpen, setIsOpen }: ClearFiltersDialogProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAnalyticsPage = location.pathname.includes(AppRoutes.ANALYTICS);

  const clearFilters = useCallback(() => {
    // In case of analytics page, clearing filters should reset the date range to This month.
    navigate(
      {
        pathname: location.pathname,
        search: isAnalyticsPage ? `dateRange=${DEFAULT_ANALYTICS_DATE_RANGE}` : '',
      },
      { replace: true }
    );
    setIsOpen(false);
  }, [location.pathname, navigate, setIsOpen]);

  return (
    <ConfirmModal
      buttonText="Clear filters"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onConfirm={clearFilters}
      title="Confirm clear filters"
      confirmText="Are you sure you want to clear all filters?"
    />
  );
};

export default ClearFiltersDialog;
