import Demographics from './Demographics';
import Personality from './Personality';
import Voice from './Voice';

const PersonalTab = () => {
  return (
    <div className="flex flex-col gap-4">
      <Demographics />
      <Personality />
      <Voice />
    </div>
  );
};

export default PersonalTab;
