import { ButtonColor, Icon, Select, TextButton, TextInput } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { randomizeScene, setBackgroundAudioPrompt } from '../../../../redux/reducers';
import ProspectPageField from '../ProspectPageField';
import TabSection from '../TabSection';

const AUDIO_SELECTION_SELECT_WIDTH = 300;

const Scene = () => {
  const dispatch = useAppDispatch();
  const { backgroundAudioPrompt } = useAppSelector((state) => state.prospect.scenario);

  const handleBgPromptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newBackgroundAudioPrompt = e.target.value;
    dispatch(setBackgroundAudioPrompt({ value: newBackgroundAudioPrompt, isUserModified: !!newBackgroundAudioPrompt }));
  };

  return (
    <TabSection title="Scene" onRandomize={() => dispatch(randomizeScene())}>
      <ProspectPageField fullWidth label="Background prompt">
        <TextInput value={backgroundAudioPrompt.value} onChange={handleBgPromptChange} />
      </ProspectPageField>
      <div className="flex w-full gap-4">
        <ProspectPageField label="Audio selection">
          <Select
            disabled={!backgroundAudioPrompt.value}
            options={[]}
            width={AUDIO_SELECTION_SELECT_WIDTH}
            onChange={() => {}}
          />
        </ProspectPageField>
        <ProspectPageField label="Preview audio">
          <TextButton color={ButtonColor.SECONDARY} disabled text="0:00/0:00" startIcon={Icon.PLAY} />
        </ProspectPageField>
      </div>
    </TabSection>
  );
};

export default Scene;
